import {FormatDatePipe} from 'src/app/pipes/format-date.pipe';
import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {Session} from '../../../../session';
import {Locale} from '../../../../locale/locale';
import {Modal} from '../../../../modal';
import {FileUtils} from '../../../../utils/file-utils';
import {AtexInspectionFieldResultLabel} from '../../../../models/atex-inspections/inspections/atex-inspection-field-result';
import {XlsxUtils} from '../../../../utils/xlsx-utils';
import {ProgressBar} from '../../../../progress-bar';
import {AtexInspectionFieldsService} from '../../inspections/services/atex-inspection-fields.service';
import {FFPPriorityLabel} from '../../../../models/atex-inspections/ffp/ffp-priority';
import {FFPStateLabel} from '../../../../models/atex-inspections/ffp/ffp-state';
import {FFP} from '../../../../models/atex-inspections/ffp/ffp';

export class FFPExport {
	/**
	 * Export FFP data as JSON.
	 */
	public static async exportJSON(): Promise<void> {
		const ffp = [];

		let from: number = 0;
		const count: number = 300;
		let hasMore = true;

		const progress = new ProgressBar();
		progress.show();

		try {
			const total = (await Service.fetch(ServiceList.atex.ffp.count, null, null, null, Session.session, true)).response.count;
			if (total > 0) {
				while (hasMore) {
					progress.update(Locale.get('loadingData'), from / total);

					const request = await Service.fetch(ServiceList.atex.ffp.listDetailed, null, null, {
						from: from,
						count: count
					}, Session.session, true);
					const response = request.response;

					for (let i = 0; i < response.ffp.length; i++) {
						ffp.push(FFP.parse(response.ffp[i]));
					}

					hasMore = response.hasMore;
					from += response.ffp.length;
				}

				FileUtils.writeFile('ffp.json', JSON.stringify(ffp, null, '\t'));
			}
		} catch (e) {
			Modal.alert(Locale.get('error'), Locale.get('errorExport'));
		}

		progress.destroy();
	}

	/**
	 * Export FFP list as XLSX file. Information is obtained from the API and the file is built on the client side.
	 */
	public static async exportXLSX(): Promise<void> {
		const fields = await AtexInspectionFieldsService.get();

		// Data to be written into the XLSX file
		const data: any[][] = [[Locale.get('ffpUuid'), Locale.get('assetUuid'), Locale.get('inspectionUuid'), 
			Locale.get('createdAt'), Locale.get('updatedAt'), Locale.get('assetName'), Locale.get('assetTag'),
			Locale.get('description'), Locale.get('state'), Locale.get('cost'), Locale.get('priority'), Locale.get('field'), Locale.get('role'), Locale.get('actionPlans'), Locale.get('question'),
			Locale.get('notes'), Locale.get('justifications'), Locale.get('recommendations'),
			Locale.get('parentUuid'), Locale.get('parentName'), Locale.get('parentTag'),
			Locale.get('result'), Locale.get('manufacturer'), Locale.get('model'), Locale.get('serialNumber')]];

		const progress = new ProgressBar();
		progress.show();

		try {
			const total = (await Service.fetch(ServiceList.atex.ffp.count, null, null, null, Session.session, true)).response.count;

			if (total > 0) {
				progress.update(Locale.get('loadingAssetsData'), 0);

				const nd = '';

				// Control data page access
				let from: number = 0;
				const count: number = 300;
				let hasMore: boolean = true;

				while (hasMore) {
					progress.update(Locale.get('loadingData'), from / total);

					const request = await Service.fetch(ServiceList.atex.ffp.listData, null, null, {
						from: from,
						count: count
					}, Session.session, true);
					const response = request.response;

					for (let i = 0; i < response.ffp.length; i++) {
						progress.update(Locale.get('loadingData'), (from + i) / total);

						const ffp = response.ffp[i];

						const asset = ffp.asset;
						const parentAsset = ffp.parentAsset;
						const inspection = ffp.inspection;

						const createdAt = FormatDatePipe.formatDateTime(new Date(ffp.createdAt));
						const updatedAt = FormatDatePipe.formatDateTime(new Date(ffp.updatedAt));

						let row = [ffp.uuid, asset.uuid, inspection.uuid, createdAt, updatedAt, asset.name, asset.tag, asset.description, Locale.get(FFPStateLabel.get(ffp.state)), ffp.cost + '€', Locale.get(FFPPriorityLabel.get(ffp.priority)), ffp.field, Locale.get(ffp.form), ffp.actionPlanCount];
						
						const field: any = fields[ffp.field];
						row.push(field?.label ? field.label : '');

						const fieldResponse = inspection.data.responses[ffp.form] && inspection.data.responses[ffp.form][ffp.field] ? inspection.data.responses[ffp.form][ffp.field] : null;

						row.push(fieldResponse ? fieldResponse.notes : nd);

						const justifications = field?.justifications ? field?.justifications : '';
						row.push(fieldResponse?.justifications ? fieldResponse.justifications.map((j) => {
							return justifications[j];
						}).join(', ') : nd);

						row.push(ffp.recommendations ? ffp.recommendations.length : 0);

						// Parent asset
						row = row.concat([parentAsset.uuid ? parentAsset.uuid : nd, parentAsset.name ? parentAsset.name : nd, parentAsset.tag ? parentAsset.tag : nd]);

						// Result (most recent result)
						row.push(fieldResponse ? fieldResponse.notApplicable ? Locale.get('notApplicable') : Locale.get(AtexInspectionFieldResultLabel.get(fieldResponse.result)) : nd);

						// Asset data						
						row.push(asset.manufacturer || nd);
						row.push(asset.model || nd);
						row.push(asset.serialNumber || nd);

						data.push(row);
					}

					from += response.ffp.length;
					hasMore = response.hasMore;
				}

				XlsxUtils.writeFile(data, 'ffp.xlsx');
			}
		} catch (e) {
			Modal.alert(Locale.get('error'), Locale.get('errorExport'));
		}

		progress.destroy();
	}
}
