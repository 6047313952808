export const Environment = {
	PRODUCTION: true,
	CLIENT_ID: 'hbfuller',
	DEFAULT_ROUTE: null,
	TEST: false,
	VERSION: '0.342.35',
	MODULES: [
		'atex-inspections',
		'asset-planning',
		'gap-analysis',
		'inspections',
		'observations',
		'repairs'
	],
	TIMESTAMP: '2024-09-05T22:09:15.814Z',
	COMMIT: 'eb9dfde4a056d66bf49b692468bee2f19cf9bd31',
	BRANCH: 'master',
	API_SERVER: 'https://hbfuller-api.unoplatform.io',
	MAPBOX_TOKEN: 'pk.eyJ1IjoidW5vM2QiLCJhIjoiY2swbWk3ZDU1MTQ2aTNubXprbnZ6Y2c1bCJ9.fGhQreB4AkAUcW_5_tr81Q',
	RESOURCE_SERVER: 'https://hbfuller-resources.unoplatform.io',
	FILE_CONVERTER_SERVER: 'https://file-converter.unoplatform.io',
	PIPELINE_INTEGRITY_SERVER: null,
	DIGITAL_TWIN_SERVER: null
};
