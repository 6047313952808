import {NgClass} from '@angular/common';
import {Component, forwardRef, Input, ViewEncapsulation} from '@angular/core';
import {ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {DateFrequency, DateFrequencyPrecision} from 'src/app/models/date-frequency';


/**
 * The component either shows the minutes toggle or doesn't, set to hours by default
 */
export enum UnoDateFrequencyType {
	MINUTES = 'minutes',
	HOURS = 'hours'
}

@Component({
	selector: 'uno-date-frequency',
	templateUrl: './uno-date-frequency.component.html',
	styleUrls: ['./uno-date-frequency.component.css'],
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => { return UnoDateFrequency; }),
		multi: true
	}],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [FormsModule, TranslateModule, NgClass]
})

export class UnoDateFrequency implements ControlValueAccessor {
	public dateFrequencyPrecision: any = DateFrequencyPrecision;

    /**
     * Controls if minutes button is shown
     */
    @Input()
	public type: DateFrequencyPrecision;

    /**
     * Value to update on the form
     */
    public value: DateFrequency = new DateFrequency();
	
    /**
     * Selected frequency type
     */
    public frequencyType: DateFrequencyPrecision = DateFrequencyPrecision.HOUR;

    /**
     * Frequency value
     */
    public frequency: number = 0;

    public onChange: (value: any)=> void = function(value) {};

    /**
     * Select the frequency type
     * 
     * @param type - The selected type
     */
    public selectFrequencyType(type: DateFrequencyPrecision): void {
    	this.frequencyType = type;
    	this.writeValue();
    }

    /**
     * When user changes the frequency value
     * 
     * @param frequency - Inputed frequency number
     */
    public onFrequencyChange(frequency: string): void {
    	this.frequency = Number(frequency);
    	this.writeValue();
    }

    public registerOnChange(onChange: any): void {
    	this.onChange = onChange;
    }

    public registerOnTouched(fn: any): void {}

    public writeValue(): void {
    	this.value = new DateFrequency();
    	this.value[this.frequencyType] = this.frequency;
    	this.onChange(this.value);
    }
}
