import {GAGap} from 'src/app/models/gap-analysis/gaps/gap';
import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {Session} from '../../../session';
import {UUID} from '../../../models/uuid';

export type GAGapListParams = {
	from?: number,
	count?: number,
	search?: string,
	searchFields?: string[],
	sortField?: string,
	sortDirection?: string,
	status?: number,
	origin?: number,
	hasRecommendations?: number,
	assetTypeUuid?: UUID,
	assetSubTypeUuid?: UUID,
	inspectionProjectUuid?: UUID
};

export type GAGapCountParams = {
	search?: string,
	searchFields?: string[],
	status?: number,
	origin?: number,
	hasRecommendations?: number,
	assetTypeUuid?: UUID,
	assetSubTypeUuid?: UUID,
	inspectionProjectUuid?: UUID
};

export type GAActionPlanListParams = {
	from?: number,
	count?: number,
	search?: string,
	sortField?: string,
	searchFields?: string[],
	sortDirection?: string,
	status?: number,
	inspectionProjectUuid?: UUID,
	assetUuid?: UUID,
};

export type GAActionPlanCountParams = {
	search?: string,
	searchFields?: string[],
	status?: number,
	inspectionProjectUuid?: UUID,
	assetUuid?: UUID,
};

export class GapAnalysisService {
	/**
	 * Gets multiple gap analysis gaps in batch by its UUIDs.
	 * 
	 * @param gapUuids - The UUIDs of the gaps to fetch from API.
	 * @returns a list of gap analysis gaps.
	 */
	public static async gatGapsBatch(gapUuids: UUID[]): Promise<GAGap[]> {
		const request = await Service.fetch(ServiceList.gapAnalysis.gap.getBatch, null, null, {gapUuids: gapUuids}, Session.session);
		return request.response.gaps;
	}

	/**
	 * List all Action plans.
	 *
	 * @param params - The parameters to apply on the fetch request.
	 */
	public static async listActionPlans(params: GAGapListParams): Promise<{hasMore: boolean, actionPlans: any[]}> {
		const request = await Service.fetch(ServiceList.gapAnalysis.actionPlan.list, null, null, params, Session.session);

		return {
			hasMore: request.response.hasMore,
			actionPlans: request.response.actionPlans
		};
	}

	/**
	 * List all gaps.
	 *
	 * @param params - The parameters to apply on the fetch request.
	 */
	public static async listGaps(params: GAGapListParams): Promise<{hasMore: boolean, gaps: any[], id: number}> {
		const request = await Service.fetch(ServiceList.gapAnalysis.gap.list, null, null, params, Session.session);
		return {
			hasMore: request.response.hasMore,
			gaps: request.response.gaps,
			id: request.id
		};
	}

	/**
	 * Delete Gap batch.
	 * 
	 * @param uuids - The uuids of the gaps to delete.
	 */
	public static async deleteGapsBatch(uuids: UUID[]): Promise<void> {
		await Service.fetch(ServiceList.gapAnalysis.gap.deleteBatch, null, null, {uuids: uuids}, Session.session);
	}

	/**
	 * Count Gaps by asset UUID.
	 * 
	 * @param assetUuid - The uuid of the asset to count the gaps for.
	 */
	public static async countGapsByAsset(assetUuid: UUID): Promise<number> {
		const request = await Service.fetch(ServiceList.gapAnalysis.gap.countByAsset, null, null, {assetUuid: assetUuid}, Session.session);
		return request.response.count;
	}

	/**
	 * Count Gaps by status.
	 * 
	 * @param status - The status to count the gaps for.
	 */
	public static async countGapsByStatus(status: number): Promise<number> {
		const request = await Service.fetch(ServiceList.gapAnalysis.gap.countByStatus, null, null, {status: status}, Session.session);
		return request.response.count;
	}

	/**
	 * Count Gaps using certain params.
	 * 
	 * @param params - The params to count the gaps for.
	 */
	public static async countGaps(params: GAGapCountParams): Promise<number> {
		const request = await Service.fetch(ServiceList.gapAnalysis.gap.count, null, null, params, Session.session);

		return request.response.count;
	}

	/**
	 * Count Action Plans using certain params.
	 * 
	 * @param params - The params to count the gaps for.
	 */
	public static async countActionPlans(params: GAActionPlanCountParams): Promise<number> {
		const request = await Service.fetch(ServiceList.gapAnalysis.actionPlan.count, null, null, params, Session.session);
		return request.response.count;
	}
}
