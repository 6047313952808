import {Component, OnInit, ViewChild} from '@angular/core';
import {SortDirection} from 'src/app/utils/sort-direction';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {NgStyle} from '@angular/common';
import {UnoTableColumnLayout, UnoTableColumnType} from 'src/app/components/uno/uno-table/uno-table.component';
import {UnoFilterBarComponent, UnoFilterBarOption, UnoFilterBarOptionType} from 'src/app/components/uno/uno-filter-bar/uno-filter-bar.component';
import {AssetSubTypeService, AssetSubtypeListParams, AssetSubtypeListResponse} from 'src/app/modules/asset-portfolio/services/asset-subtype.service';
import {UnoResponsiveTableListComponent} from 'src/app/components/uno/uno-responsive-table-list/uno-responsive-table-list.component';
import {App} from '../../../../../../app';
import {Session} from '../../../../../../session';
import {ScreenComponent} from '../../../../../../components/screen/screen.component';
import {UserPermissions} from '../../../../../../models/users/user-permissions';
import {UnoListComponent} from '../../../../../../components/uno/uno-list/uno-list-component';
import {UnoContentComponent} from '../../../../../../components/uno/uno-content/uno-content.component';
import {UnoListItemLabelComponent} from '../../../../../../components/uno/uno-list-item/uno-list-item-label.component';
import {UnoListItemComponent} from '../../../../../../components/uno/uno-list-item/uno-list-item.component';
import {UnoSearchbarComponent} from '../../../../../../components/uno/uno-searchbar/uno-searchbar.component';
import {UnoButtonComponent} from '../../../../../../components/uno/uno-button/uno-button.component';
import {PermissionsPipe} from '../../../../../../pipes/permissions.pipe';

/**
 * Page used to list asset sub-types.
 */
@Component({
	selector: 'asset-sub-type-list-page',
	templateUrl: 'asset-sub-type-list.page.html',
	standalone: true,
	imports: [
		UnoButtonComponent,
		UnoSearchbarComponent,
		IonicModule,
		FormsModule,
		UnoListItemComponent,
		UnoListItemLabelComponent,
		UnoContentComponent,
		NgStyle,
		UnoListComponent,
		TranslateModule,
		UnoFilterBarComponent,
		UnoResponsiveTableListComponent,
		PermissionsPipe
	]
})
export class AssetSubTypeListPage extends ScreenComponent implements OnInit {
	@ViewChild(UnoResponsiveTableListComponent)
	public table: UnoResponsiveTableListComponent;

	public app: any = App;

	public selfStatic: any = AssetSubTypeListPage;

	public permissions = [UserPermissions.ASSET_PORTFOLIO_ASSET_SUBTYPE];

	public session: any = Session;

	public userPermissions: any = UserPermissions;

	/**
	 * The maximum number of items to show on table component.
	 */
	public totalItems: number = 1;

	/**
	 * The number of items to show on table per page.
	 */
	public tablePageSize: number = 30;

	/**
	 * The layout to use on the Uno Table component.
	 */
	public layout: UnoTableColumnLayout[] = [
		{header: 'name', type: UnoTableColumnType.TEXT, attribute: 'name', visible: true, size: 'small', sortBy: '[ap_asset_sub_type].[name]'},
		{header: 'description', type: UnoTableColumnType.TEXT, attribute: 'description', visible: true, size: 'small', sortBy: '[ap_asset_sub_type].[description]'},
		{
			header: 'actions',
			type: UnoTableColumnType.ICONS,
			attribute: 'actions',
			visible: true,
			size: 'small',
			icons:
			[
				{
					src: './assets/icons/assets/expand-icon.svg',
					click: (row): void => {
						App.openInTab('/menu/asset-portfolio/structure/asset-sub-type/edit', {uuid: row.uuid});
					}
				}
			]
		}
	];

	/**
	 * Possible database filter to be used for ordering the Asset Subtype list.
	 */
	public static filterOptions: UnoFilterBarOption[] = [
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortDirection',
			label: 'direction',
			default: SortDirection.ASC,
			options: [
				{label: 'asc', value: SortDirection.ASC},
				{label: 'desc', value: SortDirection.DESC}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortField',
			label: 'sortField',
			default: '[ap_asset_sub_type].[name]',
			options: [
				{label: 'name', value: '[ap_asset_sub_type].[name]'},
				{label: 'createdAt', value: '[ap_asset_sub_type].[created_at]'},
				{label: 'description', value: '[ap_asset_sub_type].[description]'},
				{label: 'updatedAt', value: '[ap_asset_sub_type].[updated_at]'}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'searchFields',
			label: 'searchFields',
			default: ['[ap_asset_sub_type].[name]', '[ap_asset_sub_type].[description]'],
			multiple: true,
			options: [
				{label: 'name', value: '[ap_asset_sub_type].[name]'},
				{label: 'description', value: '[ap_asset_sub_type].[description]'}
			]
		}
	];

	public static filters = UnoFilterBarComponent.reset({
		/**
		 * Sort direction applied to the loaded list from database.
		 */
		sortDirection: '',

		/**
		 * Database attribute name used to sort the values.
		 */
		sortField: '',

		/**
		 * Text used to filter assetFormBlocks by their name, description or UUID.
		 */
		search: '',

		/**
		 * Search fields to be considered(name should match database column)
		 */
		searchFields: []
	}, AssetSubTypeListPage.filterOptions);

	public loadMore = async(count: number, pageSize: number): Promise<any> => {

		const params: AssetSubtypeListParams = {
			from: count,
			count: pageSize,
			search: AssetSubTypeListPage.filters.search,
			sortDirection: AssetSubTypeListPage.filters.sortDirection,
			sortField: AssetSubTypeListPage.filters.sortField,
			searchFields: AssetSubTypeListPage.filters.searchFields
		};

		const list: AssetSubtypeListResponse = await AssetSubTypeService.list(params);

		return {
			elements: list.subTypes,
			hasMore: list.hasMore
		};
	};

	public resetFilters(): void {
		this.table.reset();
		Object.assign(AssetSubTypeListPage.filters, AssetSubTypeListPage.defaultFilters);
	}

	public ngOnInit(): void {
		super.ngOnInit();

		App.navigator.setTitle('assetSubTypes');
	}

	/**
	 * Update filters and reload data from the API.
	 *
	 * @param event - DOM event.
	 */
	public async onFilterChange(filters: any): Promise<void> {
		AssetSubTypeListPage.filters = filters;

		this.table.sortDirection = AssetSubTypeListPage.filters.sortDirection;
		this.table.sortField = AssetSubTypeListPage.filters.sortField;

		this.totalItems = await AssetSubTypeService.count(AssetSubTypeListPage.filters);
		this.table.reset();
	}

	/**
	 * Update the search term used.
	 *
	 * @param event - DOM event.
	 */
	public async onSearch(event: any): Promise<void> {
		AssetSubTypeListPage.filters.search = event;
		this.totalItems = await AssetSubTypeService.count(AssetSubTypeListPage.filters);
		this.table.reset();
	}

	/**
	 * Change the sort direction/field used in the api list.
	 * 
	 * @param sortBy - Attribute to sort by.
	 */
	public sortChanged(sortBy: any): void {
		// If the attribute is already the current one, change the sort direction.
		if (sortBy === AssetSubTypeListPage.filters.sortField) {
			AssetSubTypeListPage.filters.sortDirection = this.table.sortDirection;
		} else {
			AssetSubTypeListPage.filters.sortField = sortBy;
			AssetSubTypeListPage.filters.sortDirection = SortDirection.ASC;
		}

		this.table.reset();
	}
}
