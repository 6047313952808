export enum DateFrequencyPrecision {
	MINUTE = 'minute',
	HOUR = 'hour',
	DAY = 'day',
	MONTH = 'month',
	YEAR = 'year'
}

/**
 * Time frequency represents the frequency of a date based event.
 *
 * These events are repeated with the frequency described in this data structure.
 */
export class DateFrequency {
	/**
	 * Number of years to be added/removed.
	 * 
	 * Leap years are taken into considerations. Year might have 365 or 366 days.
	 */
	public years: number = 0;
	
	/**
	 * Number of months to be added/removed.
	 *
	 * Takes into consideration the size of each month. Based on the specific month it might have 30, 31, 28 or 29 days.
	 */
	public months: number = 0;

	/**
	 * Number of days to be added/removed.
	 */
	public days: number = 0;

	/**
	 * Number of hours to be added/removed.
	 */
	public hours: number = 0;

	/**
	 * Number of minutes to be added/removed.
	 */
	public minutes: number = 0;

	public constructor(years: number = 0, months: number = 0, days: number = 0, hours: number = 0, minutes: number = 0) {
		this.years = years;
		this.months = months;
		this.days = days;
		this.hours = hours;
		this.minutes = minutes;
	}

	/**
	 * Parse object data into a DateFrequency object.
	 *
	 * @param data - Object data to parse.
	 * @returns DateFrequency object instance built from the received data.
	 */
	public static parse(data: any): DateFrequency {
		if (data !== null) {
			return new DateFrequency(data.years, data.months, data.days, data.hours, data.minutes);
		} 
		return new DateFrequency(0, 0, 0, 0, 0);
	}

	/**
	 * Method to check if a certain DateFrequency is empty.
	 *
	 * @param dateFrequency - DateFrequency Object to check for.
	 * @returns True if the DateFrequency object supplied is empty.
	 */
	public static isEmpty(dateFrequency: DateFrequency): boolean {
		return !dateFrequency.years && !dateFrequency.months && !dateFrequency.days && !dateFrequency.hours && !dateFrequency.minutes;
	}
}
