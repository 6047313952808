import {UUID} from '../../../models/uuid';
import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {Session} from '../../../session';
import {FormSortUtils} from '../../../utils/form-sort-utils';
import {APAssetFormTab} from '../../../models/asset-portfolio/asset-form-tab';
import {APAssetSubType} from '../../../models/asset-portfolio/asset-sub-type';
import {APAssetFormTabCard} from '../../../models/asset-portfolio/asset-form-tab-card';
import {AssetFormBlockService} from './asset-form-block.service';

export type AssetSubtypeListParams = {
	// Asset Type to filter by
	typeUuid?: UUID,
	// Search value to apply
	search?: string,
	// Fields to search by
	searchFields?: string[],
	// From where to start fetching items
	from?: number,
	// How many items to fetch
	count?: number,
	// Sort Direction to apply on the response
	sortDirection?: string,
	// Sort Field to apply on the response
	sortField?: string,
};

export type AssetSubtypeCountParams = {
	// Asset Type to filter by
	typeUuid?: UUID,
	// Search value to apply
	search?: string,
	// Fields to search by
	searchFields?: string[],
};

// Asset Subtypes list API request format.
export type AssetSubtypeListResponse = {
	// If true, there are more items to fetch on the list.
	hasMore: boolean,
	// The fetched Asset SubTypes.
	subTypes: APAssetSubType[],
	// The id of the api call.
	id: number
};

export class AssetSubTypeService {
	/**
	 * Get an asset sub-type from its UUID
	 */
	public static async getSubtype(uuid: UUID): Promise<APAssetSubType> {
		const request = await Service.fetch(ServiceList.assetPortfolio.assetSubType.get, null, null, {uuid: uuid}, Session.session);
		return APAssetSubType.parse(request.response.subType);
	}

	/**
	 * Lists asset sub type form tabs by its UUID
	 * 
	 * @param uuid - The asset subtype UUID.
	 * @returns An array with asset subtype form tabs.
	 */
	public static async listTabs(uuid: UUID): Promise<APAssetFormTab[]> {
		const request = await Service.fetch(ServiceList.assetPortfolio.formTab.assetSubType.list, null, null, {uuid: uuid}, Session.session);
		
		return request.response.tabs.map((d: any) => {
			return APAssetFormTab.parse(d);
		});
	}

	/**
	 * Lists asset subtype form tab cards by tab UUID and subtype UUID.
	 * 
	 * @param subTypeUuid - The UUID of the subtype.
	 * @param tabUuid - The form tab UUID.
	 * @returns An array with asset type form tab cards.
	 */
	public static async listTabCards(subTypeUuid: UUID, tabUuid: UUID): Promise<APAssetFormTabCard[]> {
		const request = await Service.fetch(ServiceList.assetPortfolio.formTabCard.assetSubType.list, null, null, {
			tabUuid: tabUuid,
			subTypeUuid: subTypeUuid
		}, Session.session);

		const cards: APAssetFormTabCard[] = [];
		for (let i = 0; i < request.response.cards.length; i++) {
			const card: APAssetFormTabCard = APAssetFormTabCard.parse(request.response.cards[i]);

			card.block = await AssetFormBlockService.get(card.blockUuid);

			cards.push(card);
		}

		return FormSortUtils.sortByIndexes(cards);
	}

	/*
	 * List all asset subtypes matching the params.
	 */
	public static async list(params: AssetSubtypeListParams): Promise<AssetSubtypeListResponse> {
		const request = await Service.fetch(ServiceList.assetPortfolio.assetSubType.list, null, null, params, Session.session);

		return {
			hasMore: request.response.hasMore,
			subTypes: request.response.subTypes.map((data: any) => {return APAssetSubType.parse(data);}),
			id: request.id
		};
	}

	/**
	 * Get a batch of asset subtypes by their UUIDs.
	 * 
	 * @param uuids - The UUIDs of the asset subtypes to get.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns The asset subtype objects.
	 */
	public static async getBatch(uuids: UUID[], hideLoading: boolean = false, displayError: boolean = true): Promise<APAssetSubType[]> {
		const request = await Service.fetch(ServiceList.assetPortfolio.assetSubType.getBatch, null, null, {subTypes: uuids}, Session.session, hideLoading, displayError);
		return request.response.subTypes.map((s: APAssetSubType) => { return APAssetSubType.parse(s); });
	}

	/**
	 * Count all asset subtypes matching the params.
	 */
	public static async count(params: AssetSubtypeCountParams): Promise<number> {
		return (await Service.fetch(ServiceList.assetPortfolio.assetSubType.count, null, null, params, Session.session)).response.count;
	}
}
