import {Session} from 'src/app/session';
import {UUID} from 'src/app/models/uuid';
import {DL50RegulatoryStandard} from '../../../models/dl50/masterdata/dl50-regulatory-standard';
import {DL50Question} from '../../../models/dl50/masterdata/dl50-question';
import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';

// Parameters to apply on the DL50 regulatory standards count API request.
export type DL50RegulatoryStandardsCountParams = {
	// Search value to apply
	search?: string,
	// Fields to search by
	searchFields?: string[]
};

// Parameters to apply on the DL50 regulatory standards list API request.
export type DL50RegulatoryStandardsListParams = {
	// Search value to apply
	search?: string,
	// Fields to search by
	searchFields?: string[],
	// From where to start fetching items
	from?: number,
	// How many items to fetch
	count?: number,
};

// DL50 regulatory standards list API request format.
export type DL50RegulatoryStandardsListResponse = {
	// If true, there are more items to fetch on the list.
	hasMore: boolean,
	// The fetched regulatory standards
	standards: DL50Question[],
	// Id of the request
	id: number
};

export class DL50RegulatoryStandardService {
	/**
	 * Create a regulatory standard with received data.
	 */
	public static async create(data: DL50RegulatoryStandard): Promise<UUID> {
		return (await Service.fetch(ServiceList.dl50.masterdata.regulatoryStandards.create, null, null, data, Session.session)).response.uuid;
	}

	/**
	 * Update a regulatory standard with received data.
	 */
	public static async update(data: DL50RegulatoryStandard): Promise<void> {
		await Service.fetch(ServiceList.dl50.masterdata.regulatoryStandards.update, null, null, data, Session.session);
	}

	/**
	 * Count regulatory standards matching the params.
	 */
	public static async count(params: DL50RegulatoryStandardsCountParams): Promise<number> {
		return (await Service.fetch(ServiceList.dl50.masterdata.regulatoryStandards.count, null, null, params, Session.session)).response.count;
	}

	/**
	 * List regulatory standards matching the params.
	 */
	public static async list(params: DL50RegulatoryStandardsListParams): Promise<DL50RegulatoryStandardsListResponse> {
		const request = await Service.fetch(ServiceList.dl50.masterdata.regulatoryStandards.list, null, null, params, Session.session);

		return {
			hasMore: request.response.hasMore,
			standards: request.response.standards.map((q: any) => { return DL50RegulatoryStandard.parse(q); }),
			id: request.id
		};
	}

	/**
	 * Get regulatory standards in batch by their UUIDs.
	 *
	 * @param uuids - The uuids of the regulatory standards to fetch. 
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @returns Array of Regulatory Standards that match the uuids.
	 */
	public static async getBatch(uuids: UUID[], hideLoading: boolean = false): Promise<DL50RegulatoryStandard[]> {
		const request = await Service.fetch(ServiceList.dl50.masterdata.regulatoryStandards.getBatch, null, null, {uuids: uuids}, Session.session, hideLoading);

		return request.response.standards.map((s: any) => {return DL50RegulatoryStandard.parse(s);});
	}

	/**
	 * Delete a regulatory standard by its UUID.
	 */
	public static async delete(uuid: UUID): Promise<void> {
		await Service.fetch(ServiceList.dl50.masterdata.regulatoryStandards.delete, null, null, {uuid: uuid}, Session.session);
	}
}
