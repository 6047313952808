import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {Session} from '../../../../session';
import {Locale} from '../../../../locale/locale';
import {Modal} from '../../../../modal';
import {FFPPriorityLabel} from '../../../../models/atex-inspections/ffp/ffp-priority';
import {ArrayUtils} from '../../../../utils/array-utils';
import {XlsxUtils} from '../../../../utils/xlsx-utils';
import {ProgressBar} from '../../../../progress-bar';
import {ActionPlanAction} from '../../../../models/atex-inspections/action-plan/action-plan-action';
import {ActionPlanStateLabel} from '../../../../models/atex-inspections/action-plan/action-plan-state';
import {ActionPlanActionService} from '../services/action-plan-action.service';

export class ActionPlanExport {
	/**
	 * Export action plan list as XLSX file.
	 */
	public static async exportXLSX(): Promise<void> {
		const actionsReq = await ActionPlanActionService.load();

		const data: any[][] = [[Locale.get('actionPlanUuid'), Locale.get('description'), Locale.get('workOrder'), Locale.get('state'),
			Locale.get('priority'), Locale.get('cost'), Locale.get('limitDate'),
			Locale.get('teamUuid'), Locale.get('team'),
			Locale.get('companyUuid'), Locale.get('company'),
			Locale.get('parentUuid'), Locale.get('parentName'), Locale.get('parentTag'),
			Locale.get('assetUuid'), Locale.get('assetName'), Locale.get('assetTag'),
			Locale.get('ffpUuid'), Locale.get('ffpCount'), Locale.get('field'), Locale.get('actions')]];

		const nd = '';

		let from: number = 0;
		const count: number = 300;

		const progress = new ProgressBar();
		progress.show();

		try {
			const total = (await Service.fetch(ServiceList.atex.actionPlan.count, null, null, null, Session.session, true)).response.count;
			if (total > 0) {
				let result = null;

				do {
					progress.update(Locale.get('loadingData'), from / total);

					result = await Service.fetch(ServiceList.atex.actionPlan.listData, null, null, {
						from: from,
						count: count
					}, Session.session, true);

					for (let i = 0; i < result.response.actionPlans.length; i++) {
						const actionPlan = result.response.actionPlans[i];

						const row = [actionPlan.uuid, actionPlan.description, actionPlan.workOrder, Locale.get(ActionPlanStateLabel.get(actionPlan.state)),
							Locale.get(FFPPriorityLabel.get(actionPlan.priority)), actionPlan.cost + '€', actionPlan.limitDate,
							actionPlan.team.uuid || nd, actionPlan.team.name || nd, 
							actionPlan.company.uuid || nd, actionPlan.company.name || nd];

						row.push(ArrayUtils.concatArrayAttribute(actionPlan.parentAssets, 'uuid') || nd);
						row.push(ArrayUtils.concatArrayAttribute(actionPlan.parentAssets, 'name') || nd);
						row.push(ArrayUtils.concatArrayAttribute(actionPlan.parentAssets, 'tag') || nd);

						row.push(ArrayUtils.concatArrayAttribute(actionPlan.assets, 'uuid') || nd);
						row.push(ArrayUtils.concatArrayAttribute(actionPlan.assets, 'name') || nd);
						row.push(ArrayUtils.concatArrayAttribute(actionPlan.assets, 'tag') || nd);

						row.push(ArrayUtils.concatArrayAttribute(actionPlan.ffp, 'uuid') || nd);
						row.push(actionPlan.ffp.length);
						
						row.push(ArrayUtils.concatArrayAttribute(actionPlan.ffp, 'field') || nd);

						let actions = '';
						if (actionPlan.actions) {
							for (let k = 0; k < actionPlan.actions.length; k++) {
								const action = ActionPlanAction.getAction(actionsReq.actions, actionPlan.actions[k]);
								if (action !== null) {
									if (actions.length > 0) {
										actions += ', ';
									}
									actions += action.label;
								}
							}
						}
						row.push(actions.length > 0 ? actions : nd);

						data.push(row);
					}

					from += result.response.actionPlans.length;
				} while (result.response.hasMore === true);

				// Generate and export document
				XlsxUtils.writeFile(data, 'action-plans.xlsx');
			}
		} catch (e) {
			Modal.alert(Locale.get('error'), Locale.get('errorExport'));
			console.error('EQS: Error exporting file.', e);
		}

		progress.destroy();
	}
}
