import {Team} from 'src/app/models/teams/team';
import {UUID} from '../../../models/uuid';
import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {Session} from '../../../session';

export class TeamService {
	/**
	 * Gets multiple teams in batch by their UUIDs.
	 *
	 * @param teamUUIDs - The UUIDs of the teams.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @returns Array of teams that match the UUIDs.
	 */
	public static async getBatch(teamUUIDs: UUID[], hideLoading: boolean = false): Promise<Team[]> {
		const request = await Service.fetch(ServiceList.teams.getBatch, null, null, {teamsUuids: teamUUIDs}, Session.session, hideLoading);
		return request.response.teams.map((t: Team) => { return Team.parse(t); });
	}
}
