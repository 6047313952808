import {Session} from 'src/app/session';
import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {UUID} from '../../../models/uuid';
import {EPI} from '../../../models/epi/epi';

// Parameters to apply on the EPI List api request.
export type EPIListParams = {
	// State of the epi
	state?: number,
	// Where to start fetching items
	from?: number,
	// How many items to fetch
	count?: number,
	// Search value to apply
	search?: string,
	// Fields to search by
	searchFields?: string[]
	// Sort Direction to apply
	sortDirection?: string,
	// Sort field to sort by
	sortField?: string,
};

// Parameters to apply on the EPI Count api request.
export type EPICountParams = {
	// State of the epi to count.
	state?: number,
	// Search value to apply
	search?: string,
	// Fields to search by
	searchFields?: string[]
};

export class EPIService {
	/**
	 * Get an EPI by its UUID.
	 *
	 * @param uuid - UUID of the EPI to retrieve.
	 */
	public static async get(uuid: UUID): Promise<EPI> {
		const request = await Service.fetch(ServiceList.epi.get, null, null, {uuid: uuid}, Session.session);

		return EPI.parse(request.response.epi);
	}

	/**
	 * Delete an EPI by its UUID.
	 *
	 * @param uuid - UUID of the EPI to be removed.
	 */
	public static async delete(uuid: UUID): Promise<void> {
		await Service.fetch(ServiceList.epi.delete, null, null, {uuid: uuid}, Session.session);
	}

	/*
	 * List all epi's matching the params.
	 */
	public static async list(params: EPIListParams): Promise<{hasMore: boolean, epi: any[], id: number}> {
		const request = await Service.fetch(ServiceList.epi.list, null, null, params, Session.session);

		return {
			hasMore: request.response.hasMore,
			epi: request.response.epi,
			id: request.id
		};
	}

	/**
	 * Count all epi's matching the params.
	 */
	public static async count(params: EPICountParams): Promise<number> {
		return (await Service.fetch(ServiceList.epi.count, null, null, params, Session.session)).response.count;
	}
}
