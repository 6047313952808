import {APAsset} from '../../../../models/asset-portfolio/asset';
import {FileUtils} from '../../../../utils/file-utils';
import {Locale} from '../../../../locale/locale';
import {UnoFormFieldTypes} from '../../../../components/uno-forms/uno-form/uno-form-field-types';
import {UnoFormField} from '../../../../components/uno-forms/uno-form/uno-form-field';
import {DocxUtils} from '../../../../utils/docx-utils';
import {Resource} from '../../../../models/resource';
import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {Session} from '../../../../session';
import {FFP} from '../../../../models/atex-inspections/ffp/ffp';
import {FFPPriorityLabel} from '../../../../models/atex-inspections/ffp/ffp-priority';
import {FFPRecommendation} from '../../../../models/atex-inspections/ffp/ffp-recommendation';
import {FFPStateLabel} from '../../../../models/atex-inspections/ffp/ffp-state';
import {UUID} from '../../../../models/uuid';
import {AssetReport} from '../../../asset-portfolio/data/asset-report';
import {AtexInspectionSettingsService} from '../services/atex-inspection-settings.service';
import {AtexInspectionFieldResultLabel} from '../../../../models/atex-inspections/inspections/atex-inspection-field-result';
import {AtexInspection} from '../../../../models/atex-inspections/inspections/atex-inspection';
import {AtexInspectionResultLabel} from '../../../../models/atex-inspections/inspections/atex-inspection-result';
import {ResourceUtils} from '../../../../utils/resource-utils';
import {AtexFfpService} from '../../ffp/services/atex-ffp.service';
import {AtexInspectionChecklist} from './master-data/atex-inspection-checklist';


/**
 * Handles the generation of atex inspection reports.
 *
 * These reports are generated using a docx (Microsoft Word) template that specifies the layout of the data on the file.
 */
export class AtexInspectionReport {
	/**
	 * Path of the default report template.
	 */
	public static defaultReportURL: string = 'assets/template/atex-inspection-report.docx';

	/**
	 * Generate atex inspection report, using a docx template.
	 *
	 * @param inspection - Inspection object to generate the report for.
	 * @param asset - Asset attached to the inspection entry.
	 * @param templateReport - The template to be used on report export.
	 * @returns A promise of type ArrayBuffer with the data on the docx template.
	 */
	public static async generateDocx(inspection: AtexInspection, asset: APAsset, templateReport: string = AtexInspectionReport.defaultReportURL): Promise<ArrayBuffer> {
		const settings = await AtexInspectionSettingsService.get();
		
		const template = ResourceUtils.getURL(settings.reportTemplate, templateReport);
		const arraybuffer: ArrayBuffer = await FileUtils.readFileArrayBuffer(template);

		const data = {
			// Asset
			asset: asset,
			assetData: AssetReport.getAssetData(asset),
			assetPhotos: AssetReport.getAssetImages(asset),
			atex: AssetReport.getAssetAtex(asset),

			// FFP
			ffp: await AtexInspectionReport.getFFPData(inspection.uuid),

			// Inspection
			inspection: inspection,
			inspectionPhotos: inspection.data.photo,
			inspectionDetails: AtexInspectionReport.getInspectionData(inspection),
			inspectionForm: await AtexInspectionReport.getInspectionForm(inspection),

			report: {
				date: new Date().toLocaleString(Locale.code),
				inspectionDate: new Date(inspection.updatedAt).toLocaleString(Locale.code)
			}
		};

		return DocxUtils.generateDocxFromTemplate(arraybuffer, data);
	}

	/**
	 * Get and prepare FFP data of an Atex Inspection for report template.
	 * 
	 * FFP are parsed and labels are obtained from locale.
	 */
	public static async getFFPData(inspectionUuid: UUID): Promise<any[]> {
		const request = await Service.fetch(ServiceList.atex.ffp.listDetailed, null, null, {inspectionID: inspectionUuid}, Session.session);
		const ffp: any[] = request.response.ffp;
		const ffpRecommendations: FFPRecommendation[] = await AtexFfpService.listRecommendations();

		for (let i = 0; i < ffp.length; i++) {
			ffp[i] = FFP.parse(ffp[i]);
			ffp[i].priority = Locale.get(FFPPriorityLabel.get(ffp[i].priority));
			ffp[i].state = Locale.get(FFPStateLabel.get(ffp[i].state));
			ffp[i].createdAt = ffp[i].createdAt.toLocaleString(Locale.code);
			ffp[i].createdAt = ffp[i].createdAt.toLocaleString(Locale.code);

			for (let j = 0; j < ffp[i].recommendations.length; j++) {
				for (let k = 0; k < ffpRecommendations.length; k++) {
					if (ffpRecommendations[k].id === ffp[i].recommendations[j]) {
						ffp[i].recommendations[j] = ffpRecommendations[k].label;
					}
				}
			}
		}

		return ffp;
	}

	/**
	 * Prepare inspection data to be listed in the report.
	 *
	 * @param inspection - Inspection to get data from.
	 */
	public static getInspectionData(inspection: AtexInspection): {attr: string, value: any}[] {
		return [
			{attr: Locale.get('result'), value: Locale.get(AtexInspectionResultLabel.get(inspection.result))},
			{attr: Locale.get('resultFinal'), value: Locale.get(AtexInspectionResultLabel.get(inspection.resultFinal))},
			{attr: Locale.get('liftingEquipmentRequired'), value: inspection.liftingEquipmentRequired ? Locale.get('yes') : Locale.get('no')},
			{attr: Locale.get('notes'), value: inspection.data.notes.length > 0 ? inspection.data.notes : Locale.get('nd')}
		];
	}

	/**
	 * Prepare inspection form fields to be presented in the report
	 *
	 * Contains the name of the field, label and the detailed response with result, notes, justifications, etc.
	 *
	 * @param inspection - Inspection to get data from.
	 */
	public static async getInspectionForm(inspection: AtexInspection): Promise<{attr: string, label: string, result: string, notes: string, justifications: string[], photos: Resource[]}[]> {
		const answers: {attr: string, label: string, result: string, notes: string, justifications: string[], photos: Resource[]}[] = [];
		const responses = inspection.data.responses;
		const formLayout: UnoFormField[] = await AtexInspectionChecklist.buildForm(inspection, true, true);

		for (let i = 0; i < formLayout.length; i++) {
			const row = formLayout[i];

			if (row.type === UnoFormFieldTypes.ATEX_INSPECTION_FIELD) {
				let value = null;
				if (row.backoffice) {
					value = responses.backoffice[row.attribute];
				} else if (row.inspector) {
					value = responses.inspector[row.attribute];
				}

				if (!value) {
					continue;
				}

				const result = value.notApplicable ? Locale.get('notApplicable') : Locale.get(AtexInspectionFieldResultLabel.get(value.result));

				const justifications: string[] = [];
				for (let u = 0; u < value.justifications.length; u++) {
					justifications.push(row.justifications[value.justifications[u]]);
				}

				answers.push({attr: row.attribute, label: row.label instanceof Function ? row.label(value, row) : row.label, result: result, notes: value.notes, photos: value.photo, justifications: justifications});
			}
		}

		return Promise.resolve(answers);
	}
}
