import {UnoFormFieldTypes} from '../../../../../components/uno-forms/uno-form/uno-form-field-types';
import {ArrayUtils} from '../../../../../utils/array-utils';
import {UnoFormField} from '../../../../../components/uno-forms/uno-form/uno-form-field';
import {AtexInspection} from '../../../../../models/atex-inspections/inspections/atex-inspection';
import {AtexInspectionFieldsService} from '../../services/atex-inspection-fields.service';
import {AtexInspectionChecklistService} from '../../services/atex-inspection-checklist.service';
import {AtexInspectionFormFieldType} from './atex-inspection-fields';

/**
 * Public static class contains the checklist forms for the inspection process.
 *
 * The user can select multiple checklist forms, in which case the forms are merged into a single form that contains only the common fields.
 *
 * Each checklist stores the names of the inspections fields that compose the inspection.
 */
export class AtexInspectionChecklist {
	/**
	 * Name of the checklist.
	 */
	public name: string = '';

	/**
	 * Fields that belong to the checklist.
	 */
	public fields: string[] = [];

	/**
	 * Parse data received from the API into usable checklist object.
	 * 
	 * @param data - Data received from API.
	 * @returns Atex inspection checklist object.
	 */
	public static parse(data: any): AtexInspectionChecklist {
		const checklist = new AtexInspectionChecklist();

		checklist.name = data.name;
		checklist.fields = data.fields || [];

		return checklist;
	}

	/**
	 * Get the fields used by an inspection based on the inspection types selected.
	 *
	 * @param checklist - Checklist data obtained from the server.
	 * @param inspection - Inspection data object containing the selected forms.
	 */
	public static getFormFields(checklist: any, inspection: AtexInspection): string[] {
		const labels: string[][] = [];

		for (const attr in checklist) {
			if (inspection.data.inspections[attr]) {
				labels.push(checklist[attr].fields);
			}
		}

		return ArrayUtils.mergeArrays(labels);
	}

	/**
	 * Build a form based on the selected inspections, form is built using data from master data.
	 *
	 * This data has to be retrieved from the API server.
	 *
	 * @param inspection - Inspection data object containing the selected forms.
	 * @param inspector - Flag indicating if it should contain the inspector fields.
	 * @param backoffice - Flag indicating if it should contain the back office fields.
	 */
	public static async buildForm(inspection: AtexInspection, inspector: boolean, backoffice: boolean): Promise<UnoFormField[]> {
		// Call booth methods at same time, and use a counter to sync them up
		const fields = await AtexInspectionFieldsService.get();
		const checklists = await AtexInspectionChecklistService.get();

		// List of labels that compose the Atex inspection forms, may contain repetitions.
		const labels: string[][] = [];

		// Get attribute names from
		for (const attr in checklists) {
			if (inspection.data.inspections[attr] === true) {
				labels.push(checklists[attr].fields);
			}
		}

		// Get combined list of fields that compose the inspection with repetitions being removed.
		const combined = ArrayUtils.mergeArrays(labels);

		// Build final form to be displayed in the interface.
		const form: UnoFormField[] = [];

		// Field category (A, B, C, ...) holder
		let category = null;

		for (let i = 0; i < combined.length; i++) {	
			const field: UnoFormField = fields[combined[i]] as UnoFormField;
			field.type = field.type === AtexInspectionFormFieldType.FIELD ? UnoFormFieldTypes.ATEX_INSPECTION_FIELD : UnoFormFieldTypes.TITLE;

			if (field.inspector && inspector || field.backoffice && backoffice) {
				// Get category title (A, B, C, ...)
				const character = combined[i][0];
				if (category !== character) {
					form.push(fields[character]);
				}
				category = character;
				form.push(field);
			}
		}

		return form;
	}
}

