import {Pipe, PipeTransform} from '@angular/core';
import {ObjectUtils} from '../utils/object-utils';

/**
 * Pipe to get the value of attribute of an object by its path.
 */
@Pipe({
	name: 'objectAttribute',
	standalone: true,
	pure: true
})
export class ObjectAttributePipe implements PipeTransform {
	public transform(value: any, attribute: string): any {
		return ObjectUtils.getAttribute(value, attribute);
	}
}
