<div class="uno-date-frequency-container">
	<input class="uno-date-frequency-input" type="number" (change)="this.onFrequencyChange($event.target.value)" />
	<div class="uno-date-frequency-button-group">
		@if (this.type === dateFrequencyPrecision.MINUTE) {
			<button class="uno-date-frequency-button" [ngClass]="{'uno-date-frequency-button-selected ': this.frequencyType === dateFrequencyPrecision.MINUTE}" (click)="this.selectFrequencyType(dateFrequencyPrecision.MINUTE)">{{'minute' | translate}}</button>
		}
		@if (this.type === dateFrequencyPrecision.HOUR || this.type === dateFrequencyPrecision.MINUTE) {
			<button class="uno-date-frequency-button" [ngClass]="{'uno-date-frequency-button-selected ': this.frequencyType === dateFrequencyPrecision.HOUR}" (click)="this.selectFrequencyType(dateFrequencyPrecision.HOUR)">{{'hours' | translate}}</button>
		}
		<button class="uno-date-frequency-button" [ngClass]="{'uno-date-frequency-button-selected ': this.frequencyType === dateFrequencyPrecision.DAY}" (click)="this.selectFrequencyType(dateFrequencyPrecision.DAY)">{{'days' | translate}}</button>
		<button class="uno-date-frequency-button" [ngClass]="{'uno-date-frequency-button-selected ': this.frequencyType === dateFrequencyPrecision.MONTH}" (click)="this.selectFrequencyType(dateFrequencyPrecision.MONTH)">{{'months' | translate}}</button>
		<button class="uno-date-frequency-button" [ngClass]="{'uno-date-frequency-button-selected ': this.frequencyType === dateFrequencyPrecision.YEAR}" (click)="this.selectFrequencyType(dateFrequencyPrecision.YEAR)">{{'years' | translate}}</button>
	</div>
</div>
