import {Component, OnInit} from '@angular/core';
import {SortDirection} from 'src/app/utils/sort-direction';
import {TranslateModule} from '@ngx-translate/core';
import {NgStyle} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {UnoFilterBarComponent, UnoFilterBarOption, UnoFilterBarOptionType} from 'src/app/components/uno/uno-filter-bar/uno-filter-bar.component';
import {Service} from '../../../../../http/service';
import {ServiceList} from '../../../../../http/service-list';
import {App} from '../../../../../app';
import {ScreenComponent} from '../../../../../components/screen/screen.component';
import {APAsset} from '../../../../../models/asset-portfolio/asset';
import {UserPermissions} from '../../../../../models/users/user-permissions';
import {Session} from '../../../../../session';
import {UnoNoDataComponent} from '../../../../../components/uno/uno-no-data/uno-no-data.component';
import {UnoContentComponent} from '../../../../../components/uno/uno-content/uno-content.component';
import {UnoSearchbarComponent} from '../../../../../components/uno/uno-searchbar/uno-searchbar.component';
import {AssetTreeListItemComponent} from './asset-tree-list-item/asset-tree-list-item.component';

/**
 * The extension of an asset adding its children assets
 */
export class AssetTreeItem extends APAsset {	
	/**
	 * List of asset children elements.
	 */
	public children: AssetTreeItem[] = null;
}

/**
 * Asset tree list component to display assets in a hierarchical tree.
 * 
 * Items can be filtered and sorted, operation performed on server side.
 */
@Component({
	selector: 'asset-tree-list-page',
	templateUrl: 'asset-tree-list.page.html',
	standalone: true,
	imports: [
		UnoSearchbarComponent,
		IonicModule,
		FormsModule,
		UnoContentComponent,
		NgStyle,
		UnoNoDataComponent,
		AssetTreeListItemComponent,
		TranslateModule,
		UnoFilterBarComponent
	]
})
export class AssetTreeListPage extends ScreenComponent implements OnInit {
	public selfStatic: any = AssetTreeListPage;

	public permissions = [UserPermissions.ASSET_PORTFOLIO];

	/**
	 * List of assets retrieved from the API.
	 */
	public items: AssetTreeItem[] = [];

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();
		
		this.items = [];

		App.navigator.setTitle('treeView');

		this.items = await AssetTreeListPage.loadData();
	}

	/**
	 * Possible database filter to be used for ordering the Asset Tree list.
	 */
	public static filterOptions: UnoFilterBarOption[] = [
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortDirection',
			label: 'direction',
			default: SortDirection.ASC,
			options: [
				{label: 'asc', value: SortDirection.ASC},
				{label: 'desc', value: SortDirection.DESC}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortField',
			label: 'sortField',
			default: 'name',
			options: [
				{label: 'name', value: 'name'},
				{label: 'tag', value: 'tag'},
				{label: 'createdAt', value: 'created_at'},
				{label: 'updatedAt', value: 'updated_at'}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'searchFields',
			label: 'searchFields',
			default: ['id', 'name', 'tag', 'description'],
			multiple: true,
			options: [
				{label: 'uuid', value: 'id'},
				{label: 'name', value: 'name'},
				{label: 'tag', value: 'tag'},
				{label: 'description', value: 'description'},
				{label: 'nfc', value: 'nfc'},
				{label: 'qr', value: 'qr'}
			]
		}
	];

	public static filters = UnoFilterBarComponent.reset({
		/**
		 * Text used to filter list entries by their content.
		 */
		search: '',

		/**
		 * Search fields to be considered to filter list entries by their content.
		 */
		searchFields: [],

		/**
		 * Database attribute name used to sort the values.
		 */
		sortField: '',

		/**
		 * Sort direction applied to the loaded list from database.
		 */
		sortDirection: ''
	}, AssetTreeListPage.filterOptions);

	public static defaultFilters = structuredClone(AssetTreeListPage.filters);

	/**
	 * Update filters and reload data from the API.
	 *
	 * @param event - DOM event.
	 */
	public async onFilterChange(filters: any): Promise<void> {
		this.items = await AssetTreeListPage.loadData();
	}

	/**
	 * Update the search term used.
	 *
	 * @param event - DOM event.
	 */
	public async onSearch(event: any): Promise<void> {
		AssetTreeListPage.filters.search = event;

		this.items = await AssetTreeListPage.loadData();
	}

	/**
	 * Load data from the API to display on asset tree list. Data is loaded one tree branch at a time.
	 */
	public static async loadData(): Promise<AssetTreeItem[]> {
		const data = {
			search: AssetTreeListPage.filters.search,
			searchFields: AssetTreeListPage.filters.searchFields,
			sortDirection: AssetTreeListPage.filters.sortDirection,
			sortField: AssetTreeListPage.filters.sortField
		};

		const request = await Service.fetch(ServiceList.assetPortfolio.asset.listTree, null, null, data, Session.session);		
		return request.response.assets;
	}
}
