import {SortDirection} from 'src/app/utils/sort-direction';
import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {UnoFormField} from '../../../../components/uno-forms/uno-form/uno-form-field';
import {UnoFormFieldTypes} from '../../../../components/uno-forms/uno-form/uno-form-field-types';
import {Locale} from '../../../../locale/locale';
import {Session} from '../../../../session';
import {InputOptionsMultipleBatchRequest, InputOptionsMultipleLazyPageRequest} from '../../../../components/uno-input/uno-options-lazy/uno-options-lazy.component';
import {AssetSubTypeService} from '../../services/asset-subtype.service';

/**
 * Layout of the asset type to edit AssetType entries.
 */
export const AssetTypeLayout: UnoFormField[] = [
	{
		label: 'assetType',
		type: UnoFormFieldTypes.TITLE
	},
	{
		label: 'uuid',
		attribute: 'uuid',
		type: UnoFormFieldTypes.UUID
	},
	{
		required: true,
		attribute: 'name',
		label: 'name',
		type: UnoFormFieldTypes.TEXT
	},
	{
		required: false,
		attribute: 'description',
		label: 'description',
		type: UnoFormFieldTypes.TEXT_MULTILINE
	},
	{
		required: false,
		label: 'picture',
		attribute: 'picture',
		type: UnoFormFieldTypes.IMAGE_RESOURCE
	},
	{
		label: 'structure',
		type: UnoFormFieldTypes.TITLE
	},
	{
		required: function(object) {
			return object.allowedOnRoot === false;
		},
		attribute: 'allowedParentTypesUuids',
		label: 'allowedParentTypes',
		type: UnoFormFieldTypes.OPTIONS_MULTIPLE_LAZY,
		placeholder: 'all',
		showClear: true,
		identifierAttribute: 'uuid',
		fetchOptionsLazy: function(request: InputOptionsMultipleLazyPageRequest, object: any): void {
			const data = {
				from: request.from,
				count: request.count,
				search: request.search,
				sortField: '[ap_asset_type].[name]',
				sortDirection: SortDirection.ASC
			};

			Service.call(ServiceList.assetPortfolio.assetType.listName, null, null, data, Session.session, (response, xhr, id) => {
				// When we are creating a new asset type, it isn't registered on DB yet and won't be returned and, therefore, not available to be selected as its own parent. So, we must add it manually.
				if (response.types.filter((el) => {return el.uuid === object.uuid;}).length <= 0) {
					response.types.unshift({uuid: object.uuid, name: object.name.length > 0 ? object.name : Locale.get('itself')});
				}
				request.onFinish(response.types, response.hasMore, id);
			}, () => {
				request.onError();
			});
		},
		fetchOptionsBatch: function(request: InputOptionsMultipleBatchRequest, object: any): void {
			const data = {types: request.options};

			Service.call(ServiceList.assetPortfolio.assetType.getBatch, null, null, data, Session.session, (response) => {
				if (response.types.filter((el) => {return el.uuid === object.uuid;}).length <= 0) {
					response.types.unshift({uuid: object.uuid, name: object.name.length > 0 ? object.name : Locale.get('itself')});
				}

				request.onFinish(response.types);
			});
		},
		getOptionText: function(option: any): string {
			return option.name;
		}
	},
	{
		required: false,
		attribute: 'allowedOnRoot',
		label: 'allowedOnRoot',
		type: UnoFormFieldTypes.CHECKBOX
	}
];

/**
 * Layout of the asset sub-type to edit APAssetSubType entries.
 */
export const AssetSubTypeLayout: UnoFormField[] = [
	{
		label: 'assetSubType',
		type: UnoFormFieldTypes.TITLE
	},
	{
		label: 'uuid',
		attribute: 'uuid',
		type: UnoFormFieldTypes.UUID
	},
	{
		required: true,
		attribute: 'name',
		label: 'name',
		type: UnoFormFieldTypes.TEXT
	},
	{
		required: false,
		attribute: 'description',
		label: 'description',
		type: UnoFormFieldTypes.TEXT_MULTILINE
	},
	{
		required: true,
		attribute: 'typeUuid',
		label: 'assetType',
		type: UnoFormFieldTypes.OPTIONS_MULTIPLE_LAZY,
		showClear: true,
		multiple: false,
		identifierAttribute: 'uuid',
		fetchOptionsLazy: async function(request: InputOptionsMultipleLazyPageRequest): Promise<void> {
			const data = {
				from: request.from,
				count: request.count,
				search: request.search,
				sortField: '[ap_asset_type].[name]',
				sortDirection: SortDirection.ASC
			};

			try {
				const req = await Service.fetch(ServiceList.assetPortfolio.assetType.listName, null, null, data, Session.session);
				request.onFinish(req.response.types, req.response.hasMore, req.id);
			} catch {
				request.onError();
			}
		},
		fetchOptionsBatch: async function(request: InputOptionsMultipleBatchRequest): Promise<void> {
			if (request.options.length > 0) {
				const data = {types: request.options};

				const req = await Service.fetch(ServiceList.assetPortfolio.assetType.getBatch, null, null, data, Session.session);
				request.onFinish(req.response.types);
			}
		},
		getOptionText: function(option: any): string {
			return option.name;
		}
	},
	{
		attribute: 'parentSubTypeUuid',
		label: 'parentSubType',
		type: UnoFormFieldTypes.OPTIONS_MULTIPLE_LAZY,
		showClear: true,
		required: false,
		editable: (object) => { return object.typeUuid !== null && object.typeUuid !== undefined; },
		multiple: false,
		identifierAttribute: 'uuid',
		fetchOptionsLazy: async function(request: InputOptionsMultipleLazyPageRequest, object: any): Promise<void> {
			const data = {
				from: request.from,
				count: request.count,
				search: request.search,
				typeUuid: object.typeUuid,
				subTypeUuid: object.uuid,
				sortField: '[ap_asset_sub_type].[name]',
				sortDirection: SortDirection.ASC
			};

			try {
				const req = await Service.fetch(ServiceList.assetPortfolio.assetSubType.listParentsName, null, null, data, Session.session);
				request.onFinish(req.response.subTypes, req.response.hasMore, req.id);
			} catch {
				request.onError();
			}
		},
		fetchOptionsBatch: async function(request: InputOptionsMultipleBatchRequest): Promise<void> {
			if (request.options.length >= 1) {
				const subTypes = await AssetSubTypeService.getBatch(request.options);
				request.onFinish(subTypes);
			}
		},
		getOptionText: function(option: any): string {
			return option.name;
		}
	}
];
