import {AssetFluidFamily} from 'src/app/models/asset-portfolio/asset-fluid-family';
import {AssetFluidType} from 'src/app/models/asset-portfolio/asset-fluid-type';
import {UUID} from 'src/app/models/uuid';
import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {Session} from '../../../session';

// Asset family list API request format.
export type AssetFluidFamilyListResponse = {
	// If true, there are more items to fetch on the list.
	hasMore: boolean,
	// The fetched Asset Fluid Families.
	families: AssetFluidFamily[],
	// The id of the api call.
	id: number
};

// Asset fluid type list API request format.
export type AssetFluidTypeListResponse = {
	// If true, there are more items to fetch on the list.
	hasMore: boolean,
	// The fetched Asset Fluid Types.
	types: AssetFluidType[],
	// The id of the api call.
	id: number
};

// Asset fluid type list params.
export type AssetFluidTypeListParams = {
	// The UUID of the family to filter.
	assetFluidFamilyUuid?: UUID,
};

export class AssetFluidService {
	/**
	 * Gets all the fluid families.
	 * 
	 * @returns All the fluid families.
	 */
	public static async listFamily(): Promise<AssetFluidFamilyListResponse> {
		const request = await Service.fetch(ServiceList.assetPortfolio.fluidFamily.list, null, null, {}, Session.session);

		return {
			hasMore: request.response.hasMore,
			families: request.response.families.map((f: any) => {return AssetFluidFamily.parse(f);}),
			id: request.id
		};
	}

	/**
	 * Gets all the fluid types that match the params.
	 * 
	 * @param params - the params to apply on the request
	 * 
	 * @returns All the fluid types.
	 */
	public static async listTypes(params: AssetFluidTypeListParams): Promise<AssetFluidTypeListResponse> {
		const request = await Service.fetch(ServiceList.assetPortfolio.fluidType.list, null, null, params, Session.session);
	
		return {
			hasMore: request.response.hasMore,
			types: request.response.types.map((f: any) => {return AssetFluidType.parse(f);}),
			id: request.id
		};
	}
}
