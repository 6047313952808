import {Locale} from 'src/app/locale/locale';
import {Modal} from 'src/app/modal';
import {ProgressBar} from 'src/app/progress-bar';
import {Session} from 'src/app/session';
import {XlsxSheetData, XlsxUtils} from 'src/app/utils/xlsx-utils';
import {StringUtils} from 'src/app/utils/string-utils';
import {InspectionReport} from 'src/app/modules/inspections/data/inspection/inspection-report';
import {Loading} from 'src/app/loading';
import * as XLSX from 'xlsx';
import {FileReaderAsync} from 'src/app/utils/file-reader-async';
import {InspectionFormService} from 'src/app/modules/inspections/services/inspection-form.service';
import {InspectionService} from 'src/app/modules/inspections/services/inspection.service';
import {ObjectUtils} from 'src/app/utils/object-utils';
import {InspectionWorkflowService} from 'src/app/modules/inspections/services/inspection-workflow.service';
import {FileUtils} from 'src/app/utils/file-utils';
import {TeamService} from 'src/app/modules/teams/services/teams.service';
import {AssetSubTypeService} from 'src/app/modules/asset-portfolio/services/asset-subtype.service';
import {ServiceResponse} from '../../app/http/service-response';
import {ServiceList} from '../../app/http/service-list';
import {Service} from '../../app/http/service';
import {InspectionWorkflow} from '../../app/models/inspections/workflow/inspection-workflow';
import {APAssetSubType} from '../../app/models/asset-portfolio/asset-sub-type';
import {APAssetType} from '../../app/models/asset-portfolio/asset-type';
import {Team} from '../../app/models/teams/team';
import {APAsset} from '../../app/models/asset-portfolio/asset';
import {UUID} from '../../app/models/uuid';
import {InspectionForm} from '../../app/models/inspections/form/inspection-form';
import {InspectionFormFieldType} from '../../app/modules/inspections/data/form/inspection-form-field-type';
import {Inspection} from '../../app/models/inspections/inspection/inspection';
import {InspectionWorkflowStep} from '../../app/models/inspections/workflow/inspection-workflow-step';
import {InspectionProject} from '../../app/models/inspections/project/inspection-project';
import {UnoFormField} from '../../app/components/uno-forms/uno-form/uno-form-field';
import {UnoFormFieldTypes} from '../../app/components/uno-forms/uno-form/uno-form-field-types';
import {ChecklistEvaluationData, InspectionAverageADN, InspectionAverageADNAssessmentData} from './inspection-average-adn';

export class InspectionAverageExportADN {
	/**
	 * Util to test report generation in ADN environment.
	 * 
	 * @param reportTemplateFile - The template to use for the report.
	 * @param keyQuestionsFile - The file with the key questions to use for the report.
	 * @param inspectionUuid - The UUID of the inspection to generate the report for.
	 * @param toPDF - If true, the report will be generated as a PDF file.
	 */
	public static async inspectionReport(reportTemplateFile: string, keyQuestionsFile: string, inspectionUuid: UUID, toPDF: boolean = false): Promise<void> {
		// Additional data to provide to the export
		const additionalData: any = {attributesEmpty: false};

		// All the inspection forms already fetched
		const forms: Map<UUID, InspectionForm> = new Map<UUID, InspectionForm>();
		const inspection = await InspectionService.get(inspectionUuid);
		const workflow = await InspectionWorkflowService.getFromProject(inspection.projectUuid);

		const reportTemplate = await FileUtils.readFileArrayBuffer(reportTemplateFile);
		const keyQuestions = keyQuestionsFile ? await FileUtils.readFileArrayBuffer(keyQuestionsFile) : null;

		await InspectionAverageExportADN.calcStepAverage(additionalData, inspection, workflow, forms, keyQuestions);

		let report = await InspectionReport.generateDocx(inspection, reportTemplate, additionalData);
		if (toPDF) {
			const form = new FormData();
			form.append('file', new Blob([report]), inspection.uuid + '.docx');
			report = (await Service.fetch(ServiceList.fileConverter.docxToPdf, null, null, form, Session.session)).response;
			FileUtils.writeFileArrayBuffer('report.pdf', report);
		} else {
			FileUtils.writeFileArrayBuffer('report.docx', report);
		}
	}


	/**
	 * Process a key questions file to extract the key question codes.
	 * 
	 * Can present modal to select a key questions file or use the provided file.
	 * 
	 * @param keyQuestionsXlsx - The key questions file to be used. If not provided, the user will be prompted to select a file.
	 * @returns The list of key question code and a bool indicating if the modal was canceled.
	 */
	public static async keyQuestionCodesUploadModal(keyQuestionsXlsx?: ArrayBuffer): Promise<{codes: string[], cancel: boolean}> {
		if (!keyQuestionsXlsx) {
			// Object with the needed layout attributes for the tool modal prompted to user
			const obj = {
				// File to extract the key question codes from
				file: null
			};

			const layout: UnoFormField[] = [
				{
					required: false,
					attribute: 'file',
					label: 'keyQuestionCodesFile',
					sampleData: 'assets/template/key-question-codes.xlsx',
					type: UnoFormFieldTypes.DOCUMENT_RESOURCE,
					local: true,
					filter: '.xlsx'
				}
			];

			try {
				// Present modal to select which formats should be exported and select file to import assets UUIDs data column
				await Modal.form(Locale.get('keyQuestionCodesFile'), obj, layout);
			} catch {
				return {codes: [], cancel: true};
			}

			if (!obj.file) {
				return {codes: [], cancel: false};
			}

			keyQuestionsXlsx = await FileReaderAsync.readAsArrayBuffer(obj.file);
		}

		// Extract document rows from the first sheet
		const fileContent: XLSX.WorkBook = XLSX.read(keyQuestionsXlsx, {
			type: 'array',
			cellDates: true
		});

		let rows: any[] = [];
		if (fileContent.SheetNames.length > 0) {
			rows = XLSX.utils.sheet_to_json(fileContent.Sheets[fileContent.SheetNames[0]]);
		}
	
		return {codes: InspectionAverageADN.extractKeyQuestionCodes(rows), cancel: false};
	}
	
	/**
	 * Import list of key questions and calculate step average.
	 *
	 * @param additionalData - Object to store the result, passed to the report template.
	 * @param inspection - Inspection data.
	 * @param workflow - Inspection workflow
	 * @param forms - Map with forms data by UUID.
	 * @param keyQuestionsXlsx - The key questions file to be used. If not provided, the user will be prompted to select a file.
	 * @returns True if the operation was canceled by the user
	 */
	public static async calcStepAverage(additionalData: any, inspection: Inspection, workflow: InspectionWorkflow, forms: Map<UUID, InspectionForm>, keyQuestionsXlsx?: ArrayBuffer): Promise<boolean> {
		let codes: string[] = [];

		const modal: {codes: string[], cancel: boolean} = await InspectionAverageExportADN.keyQuestionCodesUploadModal(keyQuestionsXlsx);
		if (modal.cancel) {
			return true;
		}

		Loading.show();

		codes = modal.codes;

		// Compute the inspection average for the current inspection step
		let inspectionStep: InspectionWorkflowStep = workflow.steps.find((s: InspectionWorkflowStep) => {
			return s.uuid === inspection.stepUuid;
		});

		try {			
			// Get the step with 'Fase 1' on name when the current step has valid no form to compute step average
			if (!inspectionStep?.formUuid) {
				inspectionStep = workflow.steps.find((s: InspectionWorkflowStep) => {
					return s.name === 'Fase 1';
				});
			}

			if (inspectionStep) {
				// Get all the forms and store them by their UUID to initialize inspection form data
				await InspectionReport.loadForms(inspectionStep.formUuid, forms);
	
				const evalData: ChecklistEvaluationData = await InspectionAverageADN.evaluateStepData(inspectionStep, inspection, codes, forms);
				
				// Compute inspection average value to be used on report export
				additionalData.inspectionStepAverage = evalData.answersAvg;
			} else {
				// If step form still does not exist, just set inspection step average attribute as null
				additionalData.inspectionStepAverage = null;
			}
		} catch (e) {
			Loading.hide();
			Modal.alert(Locale.get('error'), Locale.get('errorComputingInspectionAverageDetails', {details: e}));
			return true;
		}

		InspectionAverageExportADN.checkAttributeForm(additionalData, forms, inspection);

		Loading.hide();

		return false;
	}

	/**
	 * Look for the "Atributos" form and check if it is empty.
	 * 
	 * @param additionalData - Object to store the result, passed to the report template.
	 * @param forms - Map with forms data by UUID.
	 * @param inspection - Inspection data.
	 */
	public static checkAttributeForm(additionalData: any, forms: Map<UUID, InspectionForm>, inspection: Inspection): void {
		additionalData.attributesEmpty = false;

		// Search for the "Atributos" form
		let attributesForm: InspectionForm = null;
		for (const [key, form] of forms) {
			if (StringUtils.accentFolding(form.name).toLowerCase().startsWith('atributos')) {
				attributesForm = form;
			}
		}

		// Search for the "Atributos" field
		if (attributesForm) {
			for (const [key, form] of forms) {
				for (const field of form.fields) {
					if (field.subFormUuid === attributesForm.uuid) {
						// Check if the attributes form is empty
						const data = ObjectUtils.searchAttribute(inspection.data, field.uuid);
						additionalData.attributesEmpty = true;

						// Check if all fields are empty
						for (const i in data) {
							if (data[i] !== null && data[i] !== '') {
								additionalData.attributesEmpty = false;
								break;
							}
						}

					}
				}
			}
		}
	}


	/** 
	 * Inspections document header on export.
	 */
	private static inspectionBaseHeader: ()=> string[] = () => {
		return [
			Locale.get('uuid'), Locale.get('createdAt'), Locale.get('updatedAt'),
			Locale.get('name'), Locale.get('description'),
			Locale.get('projectUuid'), Locale.get('project'),
			Locale.get('assetUuid'), Locale.get('asset'), Locale.get('assetTag'), Locale.get('assetQR'), Locale.get('assetNFC'),
			Locale.get('typeUuid'), Locale.get('type'),
			Locale.get('subtypeUuid'), Locale.get('subtype'),
			Locale.get('teamUuid'), Locale.get('team'), 
			Locale.get('inspectionQR')
		];
	};

	/**
	 * Export all the inspections of all projects in a XLSX file with checklists answers values, checklists average and steps average value.
	 * 
	 * An inspection project per document sheet.
	 * 
	 * A list of key questions can be imported. Key questions are "more important" questions that will take extra importance when computing inspection evaluation.
	 */
	public static async exportXLSX(): Promise<void> {
		let codes: string[] = [];

		const codesObj: {codes: string[], cancel: boolean} = await InspectionAverageExportADN.keyQuestionCodesUploadModal();
		if (codesObj.cancel) {
			return;
		}

		codes = codesObj.codes;
		
		// The progress bar used to display export live progress on a modal
		const progressBar: ProgressBar = new ProgressBar();
		progressBar.show();

		// Create summary sheet to keep all the inspections in the same sheet
		const summarySheetData: XlsxSheetData = {
			name: Locale.get('summary'),
			data: [
				// Sheet header
				InspectionAverageExportADN.inspectionBaseHeader().concat('Fase 1', 'Fase 2', Locale.get('overallAssessment'), Locale.get('assetStatus'))		
			]
		};

		// The sheets to export on xlsx file
		const workBookSheets: XlsxSheetData[] = [summarySheetData];

		// Create caches for reusable items
		const assetsMap: Map<UUID, APAsset> = new Map();
		const teamsMap: Map<UUID, Team> = new Map();
		const typesMap: Map<UUID, APAssetType> = new Map();
		const subtypesMap: Map<UUID, APAssetSubType> = new Map();
		const formsMap: Map<UUID, InspectionForm> = new Map();

		const errors: Error[] = [];
		
		try {
			// Fetch all the projets UUIDs
			const req: ServiceResponse = await Service.fetch(ServiceList.inspection.project.list, null, null, {}, Session.session);
			const projectUuids: UUID[] = req.response.projects.map((p: any) => {return InspectionProject.parse(p).uuid;});
			
			for (let i = 0; i < projectUuids.length; i++) {
				// The global progress value computed from the number of exported projects within the total number of projects
				const progress: number = i ? (i + 1) / projectUuids.length : 0;
				progressBar.update(Locale.get('loadingData'), progress);
				
				// The sheets to store on xlsx file to be exported
				const sheetData: XlsxSheetData = await InspectionAverageExportADN.appendProjectAveragedInspectionsXLSX(projectUuids[i], projectUuids.length, codes, assetsMap, teamsMap, typesMap, subtypesMap, formsMap, progressBar, progress, errors, summarySheetData.data);
				
				// Prevent sheet name repetition that causes an error on file export
				sheetData.name = i + ' - ' + sheetData.name;
				workBookSheets.push(sheetData);
			}

			if (errors.length > 0) {
				const errs = errors.map((e) => {return '\t-' + e;}).join('\n');
				Modal.alert(Locale.get('error'), Locale.get('errorsEncounteredDuringExport', {errors: errs}));
			}
			
			if (workBookSheets.length > 0) {
				XlsxUtils.writeMultiSheetFile(workBookSheets, 'inspections.xlsx');
			} else {
				Modal.alert(Locale.get('warning'), Locale.get('nothingToExport'));
			}
		} catch (e) {
			Modal.alert(Locale.get('error'), Locale.get('errorExport'));
			console.error('EQS: Error exporting file.', e);
		} finally {
			progressBar.destroy();
		}
	}

	/**
	 * Builds a project data per sheet with all the inspections of the given project UUID, for a XLSX file.
	 * 
	 * An inspection per line and two columns per each form field on the inspection project workflow forms (one column for qualitative value from "muito bom" to "muito mau" and another for quantitative value from "1" to "5" respectively).
	 * 
	 * Extra columns are added before each checklist answers, presenting each checklist answers average value.
	 * 
	 * Extra column for each step, presenting the sum of all the checklists averages.
	 * 
	 * When an inspection has key questions with "negative" answers (higher than 3), the average value of the inspection step will be replaced by the "worst" value of answers (mau - 4 or 5 - muito Mau). This is, the inspection average does not matter for step average evaluation.
	 * 
	 * Also, non-tested question answers will be set with the highest value on scale (5) and when it is a key question, it will also dictate the inspection step final evaluation.
	 * 
	 * @param projectUuid - The UUID of the project to export.
	 * @param totalProjects - Total count of inspection projects.
	 * @param codes - The key question codes to take into analysis when computing inspection average.
	 * @param assetsMap - Cached assets map.
	 * @param teamsMap - Cached teams map.
	 * @param typesMap - Cached types map.
	 * @param subtypesMap - Cached subtypes map.
	 * @param formsMap - Cached forms map.
	 * @param progressBar - Progress bar object to be updated on data load.
	 * @param progress - Current progress of the import process.
	 * @param errors - Array to append errors found while reading data.
	 * @param summarySheetRows - Keeps the summary sheet rows data with all the inspections resume of all the projects with phase 1 and phase 2 steps only.
	 * @returns The data to be exported on project sheet.
	 */
	private static async appendProjectAveragedInspectionsXLSX(projectUuid: UUID, totalProjects: number, codes: string[], assetsMap: Map<UUID, APAsset>, teamsMap: Map<UUID, Team>, typesMap: Map<UUID, APAssetType>, subtypesMap: Map<UUID, APAssetSubType>, formsMap: Map<UUID, InspectionForm>, progressBar: ProgressBar, progress: number, errors: Error[], summarySheetRows: string[][]): Promise<XlsxSheetData> {
		let from: number = 0;
		const count: number = 300;

		// Keeps the project errors
		const projectErrors: Error[] = [];

		try {
			// Get the project info
			const project: InspectionProject = InspectionProject.parse((await Service.fetch(ServiceList.inspection.project.get, null, null, {uuid: projectUuid}, Session.session, true, false)).response.project);

			// Get project workflow steps
			let steps: InspectionWorkflowStep[] = InspectionWorkflowStep.parseArray((await Service.fetch(ServiceList.inspection.workflowStep.list, null, null, {project: projectUuid}, Session.session, true, false)).response.steps);

			// Sort steps by its index
			steps = steps.sort((a: any, b: any) => { return a.indexes - b.indexes; });

			// Build header from steps form fields
			let stepsHeaders: string[] = [];
			for (let i = 0; i < steps.length; i++) {
				if (steps[i].formUuid) {
					try {
						// Load step forms
						formsMap = await InspectionReport.loadForms(steps[i].formUuid, formsMap);
						
						// Get step forms headers
						const formsHeaders: string[] = await InspectionAverageExportADN.getFormFieldsHeaders(steps[i].formUuid, steps[i].name);
						
						// Add new column with step name to keep step checklists answers total value and step form headers
						stepsHeaders.push(steps[i].name);
						stepsHeaders = stepsHeaders.concat(formsHeaders);
					} catch (e) {
						projectErrors.push(new Error(Locale.get('errorGettingProjectFormHeaders', {stepUuid: steps[i].uuid, stepName: steps[i].name, projectUuid: project.uuid, projectName: project.name, details: e})));
					}
				} else {
					// If no step form set, just reference the step name on xlsx file header
					stepsHeaders = stepsHeaders.concat([steps[i].name]);
				}
			}

			// Data rows to insert on file (including the header)
			const sheetData: any[][] = [InspectionAverageExportADN.inspectionBaseHeader().concat([...stepsHeaders, Locale.get('errors')])];

			// Get the total inspections of this inspection project
			const projectInspectionsCount = (await Service.fetch(ServiceList.inspection.count, null, null, {projectUuid: projectUuid}, Session.session, true, false)).response.count;

			if (projectInspectionsCount > 0) {
				let inspectionCount: number = 0;

				// Control variable to tell when there's no more inspections to fetch
				let finished: boolean = false;
				while (!finished) {
					let inspections: Inspection[] = [];

					// Get all the data needed to build inspection row data beforehand
					try {
						// Get inspections with full details by project UUID and gather asset and team UUIDs to fetch them all at once for this project
						const list = await InspectionService.listDetailed({project: projectUuid, fetchData: true, from: from, count: count});
						const assetsUuids: UUID[] = [];
						const teamsUuids: UUID[] = [];
						for (let i = 0; i < list.inspections.length; i++) {
							const inspection: Inspection = list.inspections[i];
							inspections.push(inspection);
							
							if (inspection.assetUuid && !assetsMap.get(inspection.assetUuid)) {
								assetsUuids.push(inspection.assetUuid);
							}
							
							if (inspection.teamUuid && !teamsMap.get(inspection.teamUuid)) {
								teamsUuids.push(inspection.teamUuid);
							}
						}
						
						// Gather all the asset types and subtypes UUIDs to fetch them all at once for this project
						const assetTypesUuids: UUID[] = [];
						const assetSubtypesUuids: UUID[] = [];

						// Get all the assets data at once for this project
						if (assetsUuids.length > 0) {
							const assetReq = await Service.fetch(ServiceList.assetPortfolio.asset.getBatch, null, null, {assets: assetsUuids}, Session.session, true, false);
							for (let i = 0; i < assetReq.response.assets.length; i++) {
								const asset: APAsset = APAsset.parse(assetReq.response.assets[i]);
								assetsMap.set(asset.uuid, asset);

								if (asset.typeUuid && !typesMap.get(asset.typeUuid)) {
									assetTypesUuids.push(asset.typeUuid);
								}

								if (asset.subTypeUuid && !subtypesMap.get(asset.subTypeUuid)) {
									assetSubtypesUuids.push(asset.subTypeUuid);
								}
							}
						}

						// Get all the assets types data at once for this project
						if (assetTypesUuids.length > 0) {
							const assetTypeReq = await Service.fetch(ServiceList.assetPortfolio.assetType.getBatch, null, null, {types: assetTypesUuids}, Session.session, true, false);
							for (let i = 0; i < assetTypeReq.response.types.length; i++) {
								const type: APAssetType = APAssetType.parse(assetTypeReq.response.types[i]);
								typesMap.set(type.uuid, type);
							}
						}

						// Get all the assets subtypes data at once for this project
						if (assetSubtypesUuids.length > 0) {
							const subTypes = await AssetSubTypeService.getBatch(assetSubtypesUuids);
							for (let i = 0; i < subTypes.length; i++) {
								subtypesMap.set(subTypes[i].uuid, subTypes[i]);
							}
						}
		
						// Get all the teams data at once for this project
						if (teamsUuids.length > 0) {
							const teams = await TeamService.getBatch(teamsUuids);
							for (const team of teams) {
								teamsMap.set(team.uuid, team);
							}
						}

						from += list.inspections.length;
						if (!list.hasMore) {
							finished = true;
						}
					} catch (e) {
						inspections = [];
						projectErrors.push(new Error(Locale.get('errorGettingInspectionsForProject', {projectName: project.name, projectUuid: project.uuid, details: e})));
					}
	
					// Row array contains all the fields values
					let row: any[] = [];
					for (let i = 0; i < inspections.length; i++) {
						const inspection: Inspection = inspections[i];						
						
						try {
							progressBar.update(Locale.get('loadingData'), progress + inspectionCount / projectInspectionsCount / totalProjects);

							// Base headers - inspection and project data
							row = [
								inspection.uuid, inspection.createdAt.toLocaleString(Locale.code), inspection.updatedAt.toLocaleString(Locale.code),
								inspection.name, inspection.description,
								project.uuid, project.name
							];

							// Base headers - asset
							const asset: APAsset = assetsMap.get(inspection.assetUuid);
							row = row.concat(asset ? [asset.uuid, asset.name, asset.tag, asset.qr, asset.nfc] : [null, null, null, null, null]);

							let assetType: APAssetType;
							let assetSubType: APAssetSubType;
							if (asset) {
								assetType = typesMap.get(asset.typeUuid);
								// Base headers - asset type
								row = row.concat(assetType ? [assetType.uuid, assetType.name] : [null, null]);
	
								// Base headers - asset sub-type
								assetSubType = subtypesMap.get(asset.subTypeUuid);
								row = row.concat(assetSubType ? [assetSubType.uuid, assetSubType.name] : [null, null]);
							} else {
								row = row.concat([null, null, null, null]);
							}

							// Base headers - inspection team
							const inspectionTeam: Team = teamsMap.get(inspection.teamUuid);
							row = row.concat(inspectionTeam ? [inspectionTeam.uuid, inspectionTeam.name] : [null, null]);
							
							// Base headers - inspection QR
							row.push(inspection.qr ? inspection.qr : null);

							// Clone base info to the summary row data too
							let inspectionSummaryRow: any[] = structuredClone(row);
							
							// Keep the average value of step phase 1 and phase 2 checklist answers to present on summary page
							let phase1Avg: string = '';
							let phase2Avg: string = '';
							const assessmentData: InspectionAverageADNAssessmentData = {overallAssessment: null, assetStatus: null};

							const errorMessages: string[] = [];
							
							for (let j = 0; j < steps.length; j++) {
								const stepChecklistsData: ChecklistEvaluationData = await InspectionAverageADN.evaluateStepData(steps[j], inspection, codes, formsMap, assessmentData);

								const stepAvgString: string = !isNaN(stepChecklistsData.answersAvg) ? String(stepChecklistsData.answersAvg.toFixed(2)) : '';

								// Add the whole inspections answers values
								row = row.concat([stepAvgString, ...stepChecklistsData.rowData]);

								// Store phase 1 and phase 2 evaluation values
								if (StringUtils.accentFolding(steps[j].name).toLowerCase().includes('fase 1')) {
									phase1Avg = stepAvgString;
								} else if (StringUtils.accentFolding(steps[j].name).toLowerCase().includes('fase 2')) {
									phase2Avg = stepAvgString;
								}
							}
							
							// Add errors to an extra column
							const rowErrors: string[] = projectErrors.map((e) => {return e.message;}).concat(errorMessages);
							row = row.concat(rowErrors.join(' || '));
							
							// Add steps answers average values to the inspection summary row data
							inspectionSummaryRow = inspectionSummaryRow.concat(String(phase1Avg), String(phase2Avg), assessmentData.overallAssessment, assessmentData.assetStatus);
							
							// Add steps answers average values to the document sheet data containing summary rows data
							summarySheetRows.push(inspectionSummaryRow);

							// Add row data to the inspection project sheet data
							sheetData.push(row);

							inspectionCount++;
						} catch (e) {
							projectErrors.push(new Error(Locale.get('errorExportingInspectionForProject', {inspectionName: inspection.name, inspectionUuid: inspection.uuid, projectName: project.name, projectUuid: project.uuid, details: e})));
						}
					}
				}
			}

			errors.push(...projectErrors);

			return {
				name: project.name,
				data: sheetData
			};
		} catch (e) {
			errors.push(new Error('EQS: Error exporting project with UUID: ' + projectUuid + '. Details: ' + e));
		}
	}

	/**
	 * Gets the form fields names recursively to be used as document sheet headers for every form field (plus an extra header for checklist answer quantitative value) and return them on an array of strings to be inserted on sheet header.
	 *
	 * Changes on this method must be mirrored on "getFormFieldsData" method in order to ensure the correct match between headers and data.
	 *
	 * Its assumed for this specific case of ADN client to only have step forms with multiple fields of sub-form type that have the "cheklist" questions to be answered.
	 * 
	 * @param formUuid - The UUID of the form to get the headers for.
	 * @param parentHeader - The upper header label to be kept as prefix on its subfields headers.
	 * @returns An array containing all the headers/form fields name of a form plus a quantitative header for every qualitative field.
	 */
	private static async getFormFieldsHeaders(formUuid: UUID, parentHeader: string): Promise<string[]> {
		if (!formUuid) {
			throw new Error('Invalid form UUID provided when trying to get form fields headers.');
		}

		const form: InspectionForm = await InspectionFormService.get(formUuid);
		const formHeader: string = parentHeader.length > 0 ? parentHeader + ' - ' + form.name : form.name;

		let headers: string[] = [];
		for (let i = 0; i < form.fields.length; i++) {
			const fieldLabelText: string = form.fields[i].label + ' ' + form.fields[i].text;
			const fieldHeader: string = formHeader.length > 0 ? formHeader + ' - ' + fieldLabelText : fieldLabelText;

			const fieldHeaders: string[] = [
				fieldHeader,
				'[Value (1 to 5)] ' + fieldHeader
			];

			// Recursively call sub-form data
			if (form.fields[i].type === InspectionFormFieldType.MULTIPLE_FORMS) {
				throw new Error('Export of forms with repeated form fields is not supported.');
			} else if (form.fields[i].type === InspectionFormFieldType.COMPOSED_FIELD || form.fields[i].type === InspectionFormFieldType.SUB_FORM) {
				if (!form.fields[i].subFormUuid) {
					throw new Error('Invalid Subform UUID.');
				}

				const subHeaders: string[] = await InspectionAverageExportADN.getFormFieldsHeaders(form.fields[i].subFormUuid, fieldHeader);
				headers = headers.concat(Locale.get('checklistAnswersAvg'), ...subHeaders);
			} else {
				headers = headers.concat(fieldHeaders);
			}
		}
		
		return headers;
	}
}
