import {Component, OnInit, ViewChild} from '@angular/core';
import {SortDirection} from 'src/app/utils/sort-direction';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';
import {NgStyle} from '@angular/common';
import {UnoTableColumnLayout, UnoTableColumnType} from 'src/app/components/uno/uno-table/uno-table.component';
import {UnoFilterBarComponent, UnoFilterBarOption, UnoFilterBarOptionType} from 'src/app/components/uno/uno-filter-bar/uno-filter-bar.component';
import {UnoResponsiveTableListComponent} from 'src/app/components/uno/uno-responsive-table-list/uno-responsive-table-list.component';
import {UUID} from 'src/app/models/uuid';
import {UserPermissions} from '../../../../../models/users/user-permissions';
import {App} from '../../../../../app';
import {Session} from '../../../../../session';
import {ScreenComponent} from '../../../../../components/screen/screen.component';
import {UnoListComponent} from '../../../../../components/uno/uno-list/uno-list-component';
import {UnoContentComponent} from '../../../../../components/uno/uno-content/uno-content.component';
import {UnoListItemLabelComponent} from '../../../../../components/uno/uno-list-item/uno-list-item-label.component';
import {UnoListItemComponent} from '../../../../../components/uno/uno-list-item/uno-list-item.component';
import {UnoSearchbarComponent} from '../../../../../components/uno/uno-searchbar/uno-searchbar.component';
import {UnoButtonComponent} from '../../../../../components/uno/uno-button/uno-button.component';
import {InspectionFormService} from '../../../services/inspection-form.service';
import {PermissionsPipe} from '../../../../../pipes/permissions.pipe';

@Component({
	selector: 'inspection-form-list-page',
	templateUrl: 'inspection-form-list.page.html',
	standalone: true,
	imports: [
		UnoButtonComponent,
		UnoSearchbarComponent,
		UnoListItemComponent,
		UnoListItemLabelComponent,
		IonicModule,
		UnoContentComponent,
		NgStyle,
		UnoListComponent,
		TranslateModule,
		UnoResponsiveTableListComponent,
		UnoFilterBarComponent,
		PermissionsPipe
	]
})

export class InspectionFormListPage extends ScreenComponent implements OnInit {

	@ViewChild(UnoResponsiveTableListComponent) 
	public table: UnoResponsiveTableListComponent;

	public app: any = App;

	public session: any = Session;

	public userPermissions: any = UserPermissions;

	public selfStatic: any = InspectionFormListPage;

	public permissions = [UserPermissions.INSPECTION_FORM];

	/**
	 * The maximum number of items to show on table component.
	 */
	public tableTotalItemsCount: number = 100;

	/**
	 * The number of items to show on table per page.
	 */
	public static tablePageSize: number = 30;

	/**
	 * The layout to use on the Uno Table component.
	 */
	public tableLayout: UnoTableColumnLayout[] = [
		{header: 'name', type: UnoTableColumnType.TEXT, attribute: 'name', visible: true, size: 'small', sortBy: '[inspection_form].[name]'},
		{header: 'description', type: UnoTableColumnType.TEXT, attribute: 'description', visible: true, size: 'medium', sortBy: '[inspection_form].[description]'},
		{
			header: 'actions',
			type: UnoTableColumnType.ICONS,
			attribute: 'actions',
			visible: true,
			size: 'small',
			icons:
			[
				{
					src: './assets/icons/assets/expand-icon.svg',
					click: (row): void => {
						App.openInTab('/menu/inspection/form/edit', {uuid: row.uuid});
					}
				}
			]
		}
	];

	/**
	 * Possible database filter to be used for ordering the user roles list.
	 */
	public static filterOptions: UnoFilterBarOption[] = [
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortDirection',
			label: 'direction',
			default: SortDirection.ASC,
			options: [
				{label: 'asc', value: SortDirection.ASC},
				{label: 'desc', value: SortDirection.DESC}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortField',
			label: 'sortField',
			default: '[inspection_form].[name]',
			options: [
				{label: 'name', value: '[inspection_form].[name]'},
				{label: 'description', value: '[inspection_form].[description]'}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'searchFields',
			label: 'searchFields',
			default: ['[inspection_form].[name]', '[inspection_form].[description]'],
			multiple: true,
			options: [
				{label: 'name', value: '[inspection_form].[name]'},
				{label: 'description', value: '[inspection_form].[description]'},
				{label: 'uuid', value: '[inspection_form].[id]'}
			]
		}
	];
		

	public static filters = UnoFilterBarComponent.reset({
		/**
		 * Text used to filter list entries by their content.
		 */
		search: '',

		/**
		 * Sort direction applied to the loaded list from database.
		 */
		sortDirection: '',

		/**
		 * Database attribute name used to sort the values.
		 */
		sortField: '',

		/**
		 * Search fields to be considered.
		 */
		searchFields: []

	}, InspectionFormListPage.filterOptions);

	public static defaultFilters = structuredClone(InspectionFormListPage.filters);

	public loadTableItems = async(count: number, pageSize: number): Promise<any> => {
		const params = {
			from: count,
			count: pageSize,
			search: InspectionFormListPage.filters.search,
			sortField: InspectionFormListPage.filters.sortField,
			sortDirection: InspectionFormListPage.filters.sortDirection,
			searchFields: InspectionFormListPage.filters.searchFields
		};

		const list = await InspectionFormService.list(params);

		return {
			elements: list.forms,
			hasMore: list.hasMore
		};
	};

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		App.navigator.setTitle('forms');

		this.tableTotalItemsCount = await InspectionFormService.count(InspectionFormListPage.filters);
	}

	/**
	 * Update the search term used.
	 *
	 * @param event - DOM event.
	 */
	public async onSearch(event: any): Promise<void> {
		if (event.target === undefined) {
			InspectionFormListPage.filters.search = event;
		}

		this.table.reset();
		this.tableTotalItemsCount = await InspectionFormService.count(InspectionFormListPage.filters);
	}

	public async resetFilters(): Promise<void> {
		Object.assign(InspectionFormListPage.filters, InspectionFormListPage.defaultFilters);

		this.table.reset();
		this.tableTotalItemsCount = await InspectionFormService.count(InspectionFormListPage.filters);
	}

	/**
	 * Update the filters and search term used.
	 *
	 * @param filters - DOM event.
	 */
	public async onFilterChange(filters: any): Promise<void> {
		InspectionFormListPage.filters = filters;
	
		this.table.sortDirection = InspectionFormListPage.filters.sortDirection;
		this.table.sortField = InspectionFormListPage.filters.sortField;

		this.table.reset();
		this.tableTotalItemsCount = await InspectionFormService.count(InspectionFormListPage.filters);
	}

	/**
	 * Navigate to the inspection form edit page.
	 *
	 * @param uuid - The UUID of the gap to open in new page.
	 */
	public async navigate(uuid: UUID): Promise<void> {
		await App.navigator.navigate('/menu/inspection/form/edit', {uuid: uuid});
	}

	/**
	 * When the table emits a value to change which column to sort by.
	 * 
	 * @param attribute - The attribute to sort by.
	 */
	public sortChanged(attribute: string): void {
		// If the attribute is already the current one, change the sort direction.
		if (attribute === InspectionFormListPage.filters.sortField) {
			InspectionFormListPage.filters.sortDirection = this.table.sortDirection;
		} else {
			InspectionFormListPage.filters.sortField = attribute;
			InspectionFormListPage.filters.sortDirection = SortDirection.ASC;
		}

		this.table.reset();
	}
}

