import {UUID} from '../../../models/uuid';
import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {Session} from '../../../session';
import {InspectionProject} from '../../../models/inspections/project/inspection-project';


export class InspectionProjectService {
	/**
	 * Create a new inspection project in API.
	 * 
	 * @param project - The inspection project to create.
	 * @returns The UUID of the created inspection project.	
	 */	
	public static async create(project: InspectionProject): Promise<UUID> {
		const request = await Service.fetch(ServiceList.inspection.project.create, null, null, project, Session.session);
		return request.response.uuid;
	}

	/**
	 * Update the data of an inspection in API.
	 * 
	 * @param project - The inspection project to update.
	 */
	public static async update(project: InspectionProject): Promise<void> {
		await Service.fetch(ServiceList.inspection.project.update, null, null, project, Session.session);
	}

	/**
	 * Load the inspection project data from the project UUID.
	 *
	 * @param uuid - The UUID of the inspection project to load.
	 * @returns The inspection project used by the inspection.
	 */
	public static async get(uuid: UUID): Promise<InspectionProject> {
		const request = await Service.fetch(ServiceList.inspection.project.get, null, null, {uuid: uuid}, Session.session);
		return InspectionProject.parse(request.response.project);
	}

	/**
	 * List all inspection projects that match the params.
	 *
	 * @param params - The parameters to apply on the fetch request.
	 * @param hideLoading - Hide loading bar.
	 */
	public static async list(params: {
		from?: number,
		count?: number,
		search?: string,
		searchFields?: string[],
		sortField?: string,
		sortDirection?: string,
		shownInMenuOnly?: boolean
	} = {}, hideLoading: boolean = false): Promise<{hasMore: boolean, id: number, projects: InspectionProject[]}> {
		const request = await Service.fetch(ServiceList.inspection.project.list, null, null, params, Session.session, hideLoading);
		return {
			hasMore: request.response.hasMore,
			id: request.id,
			projects: request.response.projects.map((project) => {
				return InspectionProject.parse(project);
			})
		};
	}

	/**
	 * Count all inspection projects that match the params.
	 *
	 * @param params - The parameters to apply on the fetch request.
	 */
	public static async count(params: {
		search?: string,
		searchFields?: string[]
	} = {}): Promise<number> {
		const request = await Service.fetch(ServiceList.inspection.project.count, null, null, params, Session.session);
		return request.response.count;
	}
}
