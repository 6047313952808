import {Service} from 'src/app/http/service';
import {ServiceList} from 'src/app/http/service-list';
import {Experiment} from 'src/app/models/pipeline-integrity/mot/experiment';
import {UUID} from 'src/app/models/uuid';
import {Session} from 'src/app/session';

export type ExperimentListParams = {
	// Acquisition UUID to use on the request
	acquisitionUuid: UUID,
}

// Experiment list API response format.
export type ExperimentListResponse = {
	// If true, there are more items to fetch on the list.
	hasMore: boolean,

	// The fetched Experiments.
	experiments: Experiment[],

	// The id of the api call.
	id: number
};

export class ExperimentService {
	/**
	 * List all Experiments that match the params.
	 *
	 * @param params - The parameters to apply on the fetch request.
	 */
	public static async list(params: ExperimentListParams): Promise<ExperimentListResponse> {
		const request = await Service.fetch(ServiceList.pipelineIntegrity.mot.experiment.list, null, null, params, Session.session);

		return {
			hasMore: request.response.hasMore,
			id: request.id,
			experiments: request.response.experiments.map((data: any) => {return Experiment.parse(data);})
		};
	}
		
	/**
	 * Count all Experiments that match the params.
	 *
	 * @param params - The parameters to apply on the fetch request.
	 * @returns The number of Experiments that match the params.
	 */
	public static async count(params: ExperimentListParams): Promise<number> {
		const request = await Service.fetch(ServiceList.pipelineIntegrity.mot.experiment.count, null, null, params, Session.session);
		return request.response.count;
	}
}
