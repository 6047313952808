import {Component, OnInit, ViewChild} from '@angular/core';
import {SortDirection} from 'src/app/utils/sort-direction';
import {TranslateModule} from '@ngx-translate/core';
import {NgStyle} from '@angular/common';
import {UnoTableColumnLayout, UnoTableColumnType, UnoTableComponent} from 'src/app/components/uno/uno-table/uno-table.component';
import {Session} from '../../../../session';
import {App} from '../../../../app';
import {Resource} from '../../../../models/resource';
import {ScreenComponent} from '../../../../components/screen/screen.component';
import {UserPermissions} from '../../../../models/users/user-permissions';
import {ServiceSync} from '../../../../http/service-sync';
import {UnoListLazyLoadHandler} from '../../../../components/uno/uno-list/uno-list-lazy-load-handler';
import {
	UnoFilterBarComponent,
	UnoFilterBarOption,
	UnoFilterBarOptionType
} from '../../../../components/uno/uno-filter-bar/uno-filter-bar.component';
import {UnoListComponent} from '../../../../components/uno/uno-list/uno-list-component';
import {UnoContentComponent} from '../../../../components/uno/uno-content/uno-content.component';
import {UnoListItemLabelComponent} from '../../../../components/uno/uno-list-item/uno-list-item-label.component';
import {UnoListItemIconComponent} from '../../../../components/uno/uno-list-item/uno-list-item-icon.component';
import {UnoListItemComponent} from '../../../../components/uno/uno-list-item/uno-list-item.component';
import {UnoSearchbarComponent} from '../../../../components/uno/uno-searchbar/uno-searchbar.component';
import {UnoButtonComponent} from '../../../../components/uno/uno-button/uno-button.component';
import {RoleService} from '../../services/role.service';

@Component({
	selector: 'role-page',
	templateUrl: 'role.page.html',
	standalone: true,
	imports: [
		UnoButtonComponent,
		UnoSearchbarComponent,
		UnoFilterBarComponent,
		UnoListItemComponent,
		UnoListItemIconComponent,
		UnoListItemLabelComponent,
		UnoContentComponent,
		NgStyle,
		UnoListComponent,
		TranslateModule,
		UnoTableComponent
	]
})
export class RolePage extends ScreenComponent implements OnInit {

	@ViewChild(UnoTableComponent) 
	public table: UnoTableComponent;
	
	public app: any = App;

	public resource: any = Resource;

	public session: any = Session;

	public userPermissions: any = UserPermissions;

	public selfStatic: any = RolePage;

	public permissions = [UserPermissions.ROLES];

	/**
	 * Possible database filter to be used for ordering the user roles list.
	 */
	public static filterOptions: UnoFilterBarOption[] = [
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortDirection',
			label: 'direction',
			default: SortDirection.ASC,
			options: [
				{label: 'asc', value: SortDirection.ASC},
				{label: 'desc', value: SortDirection.DESC}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortField',
			label: 'sortField',
			default: 'name',
			options: [
				{label: 'name', value: 'name'},
				{label: 'createdAt', value: 'created_at'},
				{label: 'updatedAt', value: 'updated_at'}
			]
		 }
	];

	public static filters = UnoFilterBarComponent.reset({
		/**
		 * Text used to filter user roles by.
		 */
		search: '',

		/**
		 * Sort direction applied to the loaded list from database.
		 */
		sortDirection: null,

		/**
		 * Database attribute name used to sort the values.
		 */
		sortField: null
	}, RolePage.filterOptions);

	/**
	 * Lazy list loading handler.
	 */
	public handler: UnoListLazyLoadHandler<any> = new UnoListLazyLoadHandler<any>();

	/**
	 * Object to synchronize service requests.
	 */
	public serviceSync: ServiceSync = new ServiceSync();

	/**
	 * The maximum number of items to show on table component.
	 */
	public tableTotalItemsCount: number = 0;

	/**
	 * The number of items to show on table per page.
	 */
	public static tablePageSize: number = 30;

	/**
	 * The rows that are checked in the table
	 */
	public checkedTableRows: number[] = [];

	/**
	 * The layout to use on the Uno Table component.
	 */
	public tableLayout: UnoTableColumnLayout[] = [
		{header: 'name', type: UnoTableColumnType.TEXT, attribute: 'name', visible: true, size: 'small', sortBy: 'name'},
		{header: 'description', type: UnoTableColumnType.TEXT, attribute: 'description', visible: true, size: 'small'}
	];

	public loadTableItems = async(count: number, pageSize: number): Promise<any> => {
		const data = {
			sortDirection: RolePage.filters.sortDirection,
			sortField: RolePage.filters.sortField,
			from: count,
			count: pageSize,
			search: RolePage.filters.search
		};

		const list = await RoleService.listRoles(data);

		list.roles.forEach((user) => {
			Object.keys(user).forEach((key) => {
				user[key] = user[key]?.name ? user[key]?.name : user[key]?.text ? user[key]?.text : user[key] || typeof user[key] === 'number' ? user[key] : '';
			});
		});

		return {
			elements: list.roles,
			hasMore: list.hasMore
		};
	};

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		App.navigator.setTitle('userRoles');

		this.handler.loadMore = async(count: number, pageSize: number) => {
			const data = {
				sortDirection: RolePage.filters.sortDirection,
				sortField: RolePage.filters.sortField,
				from: count,
				count: pageSize,
				search: RolePage.filters.search
			};

			const list = await RoleService.listRoles(data);

			return {
				elements: list.roles,
				hasMore: list.hasMore
			};
		};
		this.handler.reset();
		this.tableTotalItemsCount = await RoleService.countRoles(RolePage.filters);
	}


	public async resetFilters(): Promise<void> {
		UnoFilterBarComponent.reset(RolePage.filters, RolePage.filterOptions);

		this.serviceSync.reset();
		await this.handler.reset();
	}

	/**
	 * Update filters and reload data from the API if required.
	 *
	 * @param search - Search value
	 */
	public async onSearchChange(search: string): Promise<void> {
		RolePage.filters.search = search;
		this.serviceSync.reset();
		await this.handler.reset();
		this.table.reset();
		this.tableTotalItemsCount = await RoleService.countRoles(RolePage.filters);
	}

	/**
	 * Update filters and reload data from the API if required.
	 *
	 * @param filters - Filters
	 */
	public async onFilterChange(filters: any): Promise<void> {
		RolePage.filters = filters;

		this.table.handler.sortDirection = RolePage.filters.sortDirection;
		this.table.handler.sortField = RolePage.filters.sortField;

		this.serviceSync.reset();
		await this.handler.reset();
		this.table.reset();
		this.tableTotalItemsCount = await RoleService.countRoles(RolePage.filters);
	}

	/**
	 * When the table emits a value to change which column to sort by.
	 * 
	 * @param attribute - The attribute to sort by.
	 */
	public sortChanged(attribute: string): void {
		// If the attribute is already the current one, change the sort direction.
		if (attribute === RolePage.filters.sortField) {
			RolePage.filters.sortDirection = RolePage.filters.sortDirection === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
		} else {
			RolePage.filters.sortField = attribute;
			RolePage.filters.sortDirection = SortDirection.ASC;
		}

		this.handler.reset();
		this.table.reset();
	}
}
