import {UnoFormField} from '../../../../components/uno-forms/uno-form/uno-form-field';
import {UnoFormFieldTypes} from '../../../../components/uno-forms/uno-form/uno-form-field-types';

/**
 * Stores the form layout to edit Gap recommendations data.
 */
export const GapRecommendationLayout: UnoFormField[] = [
	{
		required: false,
		attribute: 'items',
		type: UnoFormFieldTypes.REPETITIVE_FORM,
		fields: [
			{
				label: 'recommendation',
				type: UnoFormFieldTypes.TITLE
			},
			{
				label: 'uuid',
				attribute: 'uuid',
				type: UnoFormFieldTypes.UUID
			},
			{
				label: 'description',
				attribute: 'description',
				type: UnoFormFieldTypes.TEXT_MULTILINE
			},
			{
				label: 'quantity',
				attribute: 'quantity',
				type: UnoFormFieldTypes.NUMBER
			},
			{
				label: 'units',
				attribute: 'units',
				type: UnoFormFieldTypes.TEXT
			},
			{
				label: 'cost',
				attribute: 'cost',
				type: UnoFormFieldTypes.NUMBER
			},
			{
				label: 'documents',
				attribute: 'documents',
				type: UnoFormFieldTypes.DOCUMENT_RESOURCE_MULTIPLE
			}
		]
	}
];
