import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {Session} from '../../../session';
import {AssetSettings} from '../../../models/asset-portfolio/asset-settings';

export class AssetSettingsService {
	/**
	 * Get the master data settings from the API.
	 */
	public static async get(): Promise<AssetSettings> {
		const request = await Service.fetch(ServiceList.assetPortfolio.settings.get, null, null, {}, Session.session);
		return AssetSettings.parse(request.response.settings);
	}

	/**
	 * Update the asset settings.
	 * 
	 * @param settings - Settings object
	 */
	public static async update(settings: AssetSettings): Promise<void> {
		await Service.fetch(ServiceList.assetPortfolio.settings.update, null, null, settings, Session.session);
	}
	
}
