import {UnoFormField} from 'src/app/components/uno-forms/uno-form/uno-form-field';
import {UnoFormFieldTypes} from 'src/app/components/uno-forms/uno-form/uno-form-field-types';
import {DL50InspectionStatus, DL50InspectionStatusLabel} from 'src/app/models/dl50/dl50-inspection-status';
import {EnumUtils} from 'src/app/utils/enum-utils';
import {UUID} from 'src/app/models/uuid';
import {InputOptionsMultipleBatchRequest, InputOptionsMultipleLazyPageRequest} from 'src/app/components/uno-input/uno-options-lazy/uno-options-lazy.component';
import {DL50InspectionQuestionResult, DL50InspectionQuestionResultLabel} from '../../../../models/dl50/dl50-inspection-question-result';
import {DL50InspectionConclusion, DL50InspectionFinalConclusionLabel} from '../../../../models/dl50/dl50-inspection-conclusion';
import {DL50RegulatoryStandardService} from '../../services/dl50-regulatory-standard.service';


/**
 * Stores the form layouts to edit a DL50 inspections.
 */
export class DL50InspectionLayouts {
	/**
	 * DL50 inspection static tests assessment.
	 */
	public static field: UnoFormField[] = [
		{
			label: 'notApplicable',
			attribute: 'notApplicable',
			type: UnoFormFieldTypes.CHECKBOX
		},
		{
			label: 'evaluation',
			attribute: 'evaluation',
			type: UnoFormFieldTypes.OPTIONS,
			options: EnumUtils.values(DL50InspectionQuestionResult).map(function(value) {
				return {value: value, label: DL50InspectionQuestionResultLabel.get(value as DL50InspectionQuestionResult)};
			}),
			isActive: (object: any, row: UnoFormField): boolean => {
				return object && !object.notApplicable;
			},
			required: (object: any, row: UnoFormField): boolean => {
				return object && !object.notApplicable;
			},
			isEmpty: (object: any, row: UnoFormField): boolean => {
				return object && !object.evaluation || object.evaluation === DL50InspectionQuestionResult.NONE;
			}
		},
		{
			label: 'photos',
			attribute: 'photos',
			type: UnoFormFieldTypes.IMAGE_RESOURCE_MULTIPLE,
			isActive: (object: any, row: UnoFormField): boolean => {
				return object && !object.notApplicable;
			}
		},
		{
			label: 'comments',
			attribute: 'comments',
			type: UnoFormFieldTypes.TEXT_MULTILINE,
			isActive: (object: any, row: UnoFormField): boolean => {
				return object && !object.notApplicable;
			}
		}
	];

	/**
	 * Inspection base info form layout.
	 */
	public static baseInfo: UnoFormField[] = [
		{
			label: 'uuid',
			attribute: 'uuid',
			type: UnoFormFieldTypes.UUID
		},
		{
			label: 'status',
			attribute: 'status',
			type: UnoFormFieldTypes.OPTIONS,
			editable: false,
			sort: false,
			options: EnumUtils.values(DL50InspectionStatus).map(function(value) {
				return {value: value, label: DL50InspectionStatusLabel.get(value as DL50InspectionStatus)};
			})
		},
		{
			label: 'counter',
			attribute: 'counter',
			type: UnoFormFieldTypes.NUMBER,
			editable: false,
			isActive: (object: any, row: UnoFormField): boolean => {
				return object.counter && object.counter !== 0;
			}
		},
		{
			attribute: 'label',
			label: 'name',
			type: UnoFormFieldTypes.TEXT,
			required: false
		},
		{
			attribute: 'description',
			label: 'description',
			type: UnoFormFieldTypes.TEXT_MULTILINE,
			required: false
		},
		{
			attribute: 'assetUuid',
			label: 'asset',
			type: UnoFormFieldTypes.ASSET_SELECTOR,
			showClear: true,
			required: true
		},
		{
			attribute: 'teamUuid',
			label: 'team',
			type: UnoFormFieldTypes.TEAM_SELECTOR,
			showClear: true
		}
	];

	/**
	 * Asset information.
	 */
	public static assetInfo: UnoFormField[] = [
		{
			label: 'assetInfo',
			type: UnoFormFieldTypes.TITLE
		},
		{
			label: 'assetPhotos',
			attribute: 'pictures',
			type: UnoFormFieldTypes.IMAGE_RESOURCE_MULTIPLE,
			editable: false
		}
	];

	/**
	 * General data form layout.
	 */
	public static generalData: UnoFormField[] = [
		{
			label: 'client',
			type: UnoFormFieldTypes.TITLE
		},
		{
			label: 'company',
			attribute: 'companyUuid',
			type: UnoFormFieldTypes.COMPANY_SELECTOR,
			showClear: true
		},
		{
			label: 'authorizedOperator',
			attribute: 'authorizedOperator',
			type: UnoFormFieldTypes.CHECKBOX
		},
		{
			label: 'operator',
			attribute: 'equipmentOperator',
			isActive: (object: any, row: UnoFormField): boolean => {
				return object && !object.authorizedOperator;
			},
			type: UnoFormFieldTypes.TEXT
		},
		{
			label: 'owner',
			attribute: 'equipmentOwner',
			type: UnoFormFieldTypes.TEXT
		},
		{
			label: 'serviceHours',
			attribute: 'equipmentServiceHours',
			type: UnoFormFieldTypes.NUMBER
		},
		{
			label: 'certification',
			type: UnoFormFieldTypes.TITLE
		},
		{
			label: 'location',
			attribute: 'certificationLocation',
			type: UnoFormFieldTypes.TEXT
		},
		{
			label: 'date',
			attribute: 'certificationDate',
			type: UnoFormFieldTypes.DATE
		},
		{
			label: 'tests',
			type: UnoFormFieldTypes.TITLE
		},
		{
			label: 'hasElectricalTests',
			attribute: 'hasElectricalTests',
			type: UnoFormFieldTypes.CHECKBOX
		},
		{
			label: 'hasLoadTests',
			attribute: 'hasLoadTests',
			type: UnoFormFieldTypes.CHECKBOX
		}
	];

	/**
	 * Load test form layout.
	 */
	public static loadTestChecklist: UnoFormField[] = [
		{
			label: 'maximumProvisions',
			type: UnoFormFieldTypes.TITLE
		},
		{
			label: 'load',
			attribute: 'loadTestLoad',
			type: UnoFormFieldTypes.NUMBER_UNITS,
			inUnit: 'kg',
			unit: 'g',
			options: [
				{value: 'kg', label: 'kg'},
				{value: 'g', label: 'g'}
			]
		},
		{
			label: 'range',
			attribute: 'loadTestRange',
			type: UnoFormFieldTypes.NUMBER_UNITS,
			inUnit: 'm',
			unit: 'm',
			options: [
				{value: 'm', label: 'm'},
				{value: 'i', label: 'inch'}
			]
		},
		{
			label: 'spearLength',
			attribute: 'loadTestSpearLength',
			type: UnoFormFieldTypes.NUMBER_UNITS,
			inUnit: 'm',
			unit: 'm',
			options: [
				{value: 'm', label: 'm'},
				{value: 'i', label: 'inch'}
			]
		},
		{
			label: 'height',
			attribute: 'loadTestHeight',
			type: UnoFormFieldTypes.NUMBER_UNITS,
			inUnit: 'm',
			unit: 'm',
			options: [
				{value: 'm', label: 'm'},
				{value: 'i', label: 'inch'}
			]
		},
		{
			label: 'testData',
			type: UnoFormFieldTypes.TITLE
		},
		{
			label: 'dynamicTest',
			type: UnoFormFieldTypes.TITLE
		},
		{
			label: 'evaluation',
			attribute: 'loadTestDynamic',
			type: UnoFormFieldTypes.COMPOSED_FIELD,
			fields: DL50InspectionLayouts.field,
			expanded: true,
			isEmpty: (object: any, row: UnoFormField): boolean => {
				return object && (!object.loadTestDynamic || object.loadTestDynamic === DL50InspectionQuestionResult.NONE);
			}
		},
		{
			label: 'loadTestOverload',
			attribute: 'loadTestDynamicOverload',
			type: UnoFormFieldTypes.NUMBER,
			isActive: (object: any, row: UnoFormField): boolean => {
				return object && !object.loadTestDynamic.notApplicable;
			}
		},
		{
			label: 'load',
			attribute: 'loadTestDynamicLoad',
			type: UnoFormFieldTypes.NUMBER_UNITS,
			inUnit: 'kg',
			unit: 'g',
			options: [
				{value: 'kg', label: 'kg'},
				{value: 'g', label: 'g'}
			],
			isActive: (object: any, row: UnoFormField): boolean => {
				return object && !object.loadTestDynamic.notApplicable;
			}
		},
		{
			label: 'range',
			attribute: 'loadTestDynamicRange',
			type: UnoFormFieldTypes.NUMBER_UNITS,
			inUnit: 'm',
			unit: 'm',
			options: [
				{value: 'm', label: 'm'},
				{value: 'i', label: 'inch'}
			],
			isActive: (object: any, row: UnoFormField): boolean => {
				return object && !object.loadTestDynamic.notApplicable;
			}
		},
		{
			label: 'loadTestStaticTest',
			type: UnoFormFieldTypes.TITLE
		},
		{
			label: 'evaluation',
			attribute: 'loadTestStatic',
			type: UnoFormFieldTypes.COMPOSED_FIELD,
			fields: DL50InspectionLayouts.field
		},
		{
			label: 'loadTestOverload',
			attribute: 'loadTestStaticOverload',
			type: UnoFormFieldTypes.NUMBER,
			isActive: (object: any, row: UnoFormField): boolean => {
				return object && !object.loadTestStatic.notApplicable;
			}
		},
		{
			label: 'load',
			attribute: 'loadTestStaticLoad',
			type: UnoFormFieldTypes.NUMBER_UNITS,
			inUnit: 'kg',
			unit: 'g',
			options: [
				{value: 'kg', label: 'kg'},
				{value: 'g', label: 'g'}
			],
			isActive: (object: any, row: UnoFormField): boolean => {
				return object && !object.loadTestStatic.notApplicable;
			}
		},
		{
			label: 'range',
			attribute: 'loadTestStaticRange',
			type: UnoFormFieldTypes.NUMBER_UNITS,
			inUnit: 'm',
			unit: 'm',
			options: [
				{value: 'm', label: 'm'},
				{value: 'i', label: 'inch'}
			],
			isActive: (object: any, row: UnoFormField): boolean => {
				return object && !object.loadTestStatic.notApplicable;
			}
		},
		{
			label: 'loadTestFunctional',
			type: UnoFormFieldTypes.TITLE
		},
		{
			label: 'loadTestNoLoad',
			attribute: 'loadTestFunctional',
			type: UnoFormFieldTypes.COMPOSED_FIELD,
			fields: DL50InspectionLayouts.field
		},
		{
			label: 'load',
			attribute: 'loadTestFunctionalLoad',
			type: UnoFormFieldTypes.NUMBER_UNITS,
			inUnit: 'kg',
			unit: 'g',
			options: [
				{value: 'kg', label: 'kg'},
				{value: 'g', label: 'g'}
			],
			isActive: (object: any, row: UnoFormField): boolean => {
				return object && !object.loadTestFunctional.notApplicable;
			}
		},
		{
			label: 'range',
			attribute: 'loadTestFunctionalRange',
			type: UnoFormFieldTypes.NUMBER_UNITS,
			inUnit: 'm',
			unit: 'm',
			options: [
				{value: 'm', label: 'm'},
				{value: 'i', label: 'inch'}
			],
			isActive: (object: any, row: UnoFormField): boolean => {
				return object && !object.loadTestFunctional.notApplicable;
			}
		},
		{
			label: 'loadTestMomentLimiter',
			type: UnoFormFieldTypes.TITLE
		},
		{
			label: 'loadTestTenPercentOverloadAssessment',
			attribute: 'loadTestMomentLimiter',
			type: UnoFormFieldTypes.COMPOSED_FIELD,
			fields: DL50InspectionLayouts.field
		},
		{
			label: 'load',
			attribute: 'loadTestMomentLimiterLoad',
			type: UnoFormFieldTypes.NUMBER_UNITS,
			inUnit: 'kg',
			unit: 'g',
			options: [
				{value: 'kg', label: 'kg'},
				{value: 'g', label: 'g'}
			],
			isActive: (object: any, row: UnoFormField): boolean => {
				return object && !object.loadTestMomentLimiter.notApplicable;
			}
		},
		{
			label: 'range',
			attribute: 'loadTestMomentLimiterRange',
			type: UnoFormFieldTypes.NUMBER_UNITS,
			inUnit: 'm',
			unit: 'm',
			options: [
				{value: 'm', label: 'm'},
				{value: 'i', label: 'inch'}
			],
			isActive: (object: any, row: UnoFormField): boolean => {
				return object && !object.loadTestMomentLimiter.notApplicable;
			}
		},
		{
			label: 'loadTestMaxLoadLimiter',
			type: UnoFormFieldTypes.TITLE
		},
		{
			label: 'loadTestTenPercentOverloadAssessment',
			attribute: 'loadTestMaxLoadLimiter',
			type: UnoFormFieldTypes.COMPOSED_FIELD,
			fields: DL50InspectionLayouts.field
		},
		{
			label: 'load',
			attribute: 'loadTestMaxLoadLimiterLoad',
			type: UnoFormFieldTypes.NUMBER_UNITS,
			inUnit: 'kg',
			unit: 'g',
			options: [
				{value: 'kg', label: 'kg'},
				{value: 'g', label: 'g'}
			],
			isActive: (object: any, row: UnoFormField): boolean => {
				return object && !object.loadTestMaxLoadLimiter.notApplicable;
			}
		},
		{
			label: 'range',
			attribute: 'loadTestMaxLoadLimiterRange',
			type: UnoFormFieldTypes.NUMBER_UNITS,
			inUnit: 'm',
			unit: 'm',
			options: [
				{value: 'm', label: 'm'},
				{value: 'i', label: 'inch'}
			],
			isActive: (object: any, row: UnoFormField): boolean => {
				return object && !object.loadTestMaxLoadLimiter.notApplicable;
			}
		}
	];

	/**
	 * Electrical test form layout.
	 */
	public static electricalTestChecklist: UnoFormField[] = [
		{
			label: 'assetInfo',
			type: UnoFormFieldTypes.TITLE
		},
		{
			label: 'frequency',
			attribute: 'frequency',
			required: (object: any, row: UnoFormField): boolean => {
				return object?.hasElectricalTests;
			},
			type: UnoFormFieldTypes.NUMBER_UNITS,
			inUnit: 'Hz',
			unit: 'Hz',
			options: [
				{value: 'Hz', label: 'Hz'},
				{value: 'kHz', label: 'kHz'},
				{value: 'MHz', label: 'MHz'},
				{value: 'GHz', label: 'GHz'}
			]
		},
		{
			label: 'nominalVoltage',
			attribute: 'nominalVoltage',
			required: (object: any, row: UnoFormField): boolean => {
				return object?.hasElectricalTests;
			},
			type: UnoFormFieldTypes.NUMBER_UNITS,
			inUnit: 'V',
			unit: 'V',
			options: [
				{value: 'V', label: 'V'},
				{value: 'kV', label: 'kV'},
				{value: 'MV', label: 'MV'},
				{value: 'GV', label: 'GV'}
			]
		},
		{
			label: 'groundCircuitContinuity',
			type: UnoFormFieldTypes.TITLE
		},
		{
			label: 'evaluation',
			attribute: 'groundCircuitContinuity',
			type: UnoFormFieldTypes.COMPOSED_FIELD,
			fields: DL50InspectionLayouts.field
		},
		{
			label: 'result',
			attribute: 'groundCircuitContinuityResult',
			type: UnoFormFieldTypes.NUMBER_UNITS,
			inUnit: 'Ω',
			unit: 'Ω',
			options: [
				{value: 'Ω', label: 'Ω'},
				{value: 'kΩ', label: 'kΩ'}
			],
			isActive: (object: any, row: UnoFormField): boolean => {
				return object && !object.groundCircuitContinuity.notApplicable;
			}
		},
		{
			label: 'electricCurrent',
			attribute: 'groundCircuitContinuityCurrent',
			type: UnoFormFieldTypes.NUMBER_UNITS,
			inUnit: 'mA',
			unit: 'A',
			options: [
				{value: 'mA', label: 'mA'},
				{value: 'A', label: 'A'}
			],
			isActive: (object: any, row: UnoFormField): boolean => {
				return object && !object.groundCircuitContinuity.notApplicable;
			}
		},
		{
			label: 'differentialSwitches',
			type: UnoFormFieldTypes.TITLE
		},
		{
			label: 'evaluation',
			attribute: 'differentialSwitches',
			type: UnoFormFieldTypes.COMPOSED_FIELD,
			fields: DL50InspectionLayouts.field
		},
		{
			label: 'differentialSwitchesOpenCircuitTimeHalf',
			attribute: 'differentialSwitchesOpenCircuitTimeHalf',
			type: UnoFormFieldTypes.NUMBER_UNITS,
			inUnit: 'ms',
			unit: 's',
			options: [
				{value: 'ms', label: 'ms'},
				{value: 's', label: 's'}
			],
			isActive: (object: any, row: UnoFormField): boolean => {
				return object && !object.differentialSwitches.notApplicable;
			}
		},
		{
			label: 'differentialSwitchesOpenCircuitTimeOne',
			attribute: 'differentialSwitchesOpenCircuitTimeOne',
			type: UnoFormFieldTypes.NUMBER_UNITS,
			inUnit: 'ms',
			unit: 's',
			options: [
				{value: 'ms', label: 'ms'},
				{value: 's', label: 's'}
			],
			isActive: (object: any, row: UnoFormField): boolean => {
				return object && !object.differentialSwitches.notApplicable;
			}
		},
		{
			label: 'differentialSwitchesOpenCircuitTimeTwo',
			attribute: 'differentialSwitchesOpenCircuitTimeTwo',
			type: UnoFormFieldTypes.NUMBER_UNITS,
			inUnit: 'ms',
			unit: 's',
			options: [
				{value: 'ms', label: 'ms'},
				{value: 's', label: 's'}
			],
			isActive: (object: any, row: UnoFormField): boolean => {
				return object && !object.differentialSwitches.notApplicable;
			}
		},
		{
			label: 'differentialSwitchesOpenCircuitTimeFive',
			attribute: 'differentialSwitchesOpenCircuitTimeFive',
			type: UnoFormFieldTypes.NUMBER_UNITS,
			inUnit: 'ms',
			unit: 's',
			options: [
				{value: 'ms', label: 'ms'},
				{value: 's', label: 's'}
			],
			isActive: (object: any, row: UnoFormField): boolean => {
				return object && !object.differentialSwitches.notApplicable;
			}
		},
		{
			label: 'insulationResistance',
			type: UnoFormFieldTypes.TITLE
		},
		{
			label: 'evaluation',
			attribute: 'insulationResistance',
			type: UnoFormFieldTypes.COMPOSED_FIELD,
			fields: DL50InspectionLayouts.field
		},
		{
			label: 'insulationResistanceR',
			attribute: 'insulationResistanceR',
			type: UnoFormFieldTypes.NUMBER_UNITS,
			inUnit: 'MΩ',
			unit: 'Ω',
			options: [
				{value: 'MΩ', label: 'MΩ'},
				{value: 'Ω', label: 'Ω'}
			],
			isActive: (object: any, row: UnoFormField): boolean => {
				return object && !object.insulationResistance.notApplicable;
			}
		},
		{
			label: 'insulationResistanceS',
			attribute: 'insulationResistanceS',
			type: UnoFormFieldTypes.NUMBER_UNITS,
			inUnit: 'MΩ',
			unit: 'Ω',
			options: [
				{value: 'MΩ', label: 'MΩ'},
				{value: 'Ω', label: 'Ω'}
			],
			isActive: (object: any, row: UnoFormField): boolean => {
				return object && !object.insulationResistance.notApplicable;
			}
		},
		{
			label: 'insulationResistanceT',
			attribute: 'insulationResistanceT',
			type: UnoFormFieldTypes.NUMBER_UNITS,
			inUnit: 'MΩ',
			unit: 'Ω',
			options: [
				{value: 'MΩ', label: 'MΩ'},
				{value: 'Ω', label: 'Ω'}
			],
			isActive: (object: any, row: UnoFormField): boolean => {
				return object && !object.insulationResistance.notApplicable;
			}
		},
		{
			label: 'insulationResistanceN',
			attribute: 'insulationResistanceN',
			type: UnoFormFieldTypes.NUMBER_UNITS,
			inUnit: 'MΩ',
			unit: 'Ω',
			options: [
				{value: 'MΩ', label: 'MΩ'},
				{value: 'Ω', label: 'Ω'}
			],
			isActive: (object: any, row: UnoFormField): boolean => {
				return object && !object.insulationResistance.notApplicable;
			}
		}
	];

	/**
	 * Inspection final notes form layout.
	 */
	public static inspectionFinalNotes: UnoFormField[] = [
		{
			label: 'finalNotes',
			type: UnoFormFieldTypes.TITLE
		},
		{
			label: 'inspector',
			attribute: 'finalNotesUserUuid',
			type: UnoFormFieldTypes.USER_SELECTOR,
			showClear: true,
			required: true
		},
		{
			label: 'conclusion',
			attribute: 'finalNotesConclusion',
			required: true,
			type: UnoFormFieldTypes.OPTIONS,
			options: EnumUtils.values(DL50InspectionConclusion).map((value) => {
				return {label: DL50InspectionFinalConclusionLabel.get(value), value: value};
			}),
			isEmpty: (object: any, row: UnoFormField): boolean => {
				return object && (!object.finalNotesConclusion || object.finalNotesConclusion === DL50InspectionConclusion.NONE);
			}
		},
		{
			label: 'correctionDeadline',
			attribute: 'correctionDeadline',
			type: UnoFormFieldTypes.NUMBER_UNITS,
			inUnit: 'day',
			unit: 'day',
			isActive: (object: any, row: UnoFormField): boolean => {
				return object && (object.finalNotesConclusion === DL50InspectionConclusion.GAPS_LIGHT || object.finalNotesConclusion === DL50InspectionConclusion.GAPS_SEVERE);
			},
			options: [
				{value: 'day', label: 'day'}
			]
		},
		{
			label: 'legislationAndStandards',
			type: UnoFormFieldTypes.TITLE
		},
		{
			label: 'standards',
			attribute: 'regulatoryStandardUuids',
			type: UnoFormFieldTypes.OPTIONS_MULTIPLE_LAZY,
			identifierAttribute: 'uuid',
			fetchOptionsLazy: async function(request: InputOptionsMultipleLazyPageRequest): Promise<void> {
				const data = {
					from: request.from,
					count: request.count,
					search: request.search
				};

				try {
					const req = await DL50RegulatoryStandardService.list(data);
					request.onFinish(req.standards, req.hasMore, req.id);
				} catch {
					request.onError();
				}
			},
			fetchOptionsBatch: async function(request: InputOptionsMultipleBatchRequest): Promise<void> {
				if (request.options.length > 0) {
					request.onFinish(await DL50RegulatoryStandardService.getBatch(request.options as UUID[]));
				}
			},
			getOptionText: function(option: any): string {
				return (option.label ? option.label : '') + (option.description ? ' - ' + option.description : '');
			}
		}
	];

	/**
	 * Inspection validation form layout.
	 */
	public static inspectionValidation: UnoFormField[] = [
		{
			label: 'validation',
			type: UnoFormFieldTypes.TITLE
		},
		{
			label: 'comments',
			attribute: 'validationComments',
			type: UnoFormFieldTypes.TEXT_MULTILINE
		},
		{
			label: 'reviewer',
			attribute: 'validationUserUuid',
			type: UnoFormFieldTypes.USER_SELECTOR,
			showClear: true,
			required: true
		},
		{
			label: 'emission',
			type: UnoFormFieldTypes.TITLE
		},
		{
			label: 'location',
			attribute: 'validationEmissionLocation',
			type: UnoFormFieldTypes.TEXT,
			required: true
		},
		{
			label: 'date',
			attribute: 'validationEmissionDate',
			type: UnoFormFieldTypes.DATE,
			required: true
		},
		{
			label: 'revision',
			attribute: 'revision',
			type: UnoFormFieldTypes.NUMBER,
			editable: false,
			isActive: (object: any, row: UnoFormField): boolean => {
				return object?.revision !== null && object?.revision !== undefined;
			}
		}
	];

	/**
	 * Client feedback form layout.
	 */
	public static inspectionClientFeedback: UnoFormField[] = [
		{
			label: 'clientFeedback',
			type: UnoFormFieldTypes.TITLE
		},
		{
			label: 'comments',
			attribute: 'clientFeedback',
			type: UnoFormFieldTypes.TEXT_MULTILINE
		}
	];

	/**
	 * Inspection supervision rejected form layout.
	 */
	public static inspectionSupervisionRejected: UnoFormField[] = [
		{
			label: 'comments',
			attribute: 'supervisionRejectedComments',
			type: UnoFormFieldTypes.TEXT_MULTILINE
		}
	];
}
