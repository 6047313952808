import {Session} from 'src/app/session';
import {Locale} from 'src/app/locale/locale';
import {FormatDatePipe} from 'src/app/pipes/format-date.pipe';
import {GAGapOrigin, GAGapOriginLabel} from 'src/app/models/gap-analysis/gaps/gap-origin';
import {InputOptionsMultipleBatchRequest} from '../../../../components/uno-input/uno-options-lazy/uno-options-lazy.component';
import {UnoFormFieldTypes} from '../../../../components/uno-forms/uno-form/uno-form-field-types';
import {UnoFormField} from '../../../../components/uno-forms/uno-form/uno-form-field';
import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {DateFrequencyPrecision} from '../../../../models/date-frequency';
import {GAActionPlanStatus, GAActionPlanStatusLabel} from '../../../../models/gap-analysis/action-plans/action-plan-status';
import {GAActionPlanCriticality, GAActionPlanCriticalityLabel} from '../../../../models/gap-analysis/action-plans/action-plan-criticality';
import {GAActionPlanAlarmType, GAActionPlanAlarmTypeLabel} from '../../../../models/gap-analysis/action-plans/action-plan-alarm-type';

/**
 * Layout of the form to edit gap analysis action plan entries.
 */
export const GAActionPlanFormLayout: UnoFormField[] = [
	{
		label: 'uuid',
		attribute: 'uuid',
		type: UnoFormFieldTypes.UUID
	},
	{
		attribute: 'description',
		label: 'description',
		required: false,
		type: UnoFormFieldTypes.TEXT_MULTILINE
	},
	{
		attribute: 'status',
		label: 'status',
		sort: false,
		type: UnoFormFieldTypes.OPTIONS,
		editable: false,
		options: Object.values(GAActionPlanStatus).map(function(value) {
			return {value: value, label: GAActionPlanStatusLabel.get(value)};
		})
	},
	{
		required: false,
		attribute: 'cost',
		label: 'cost',
		type: UnoFormFieldTypes.NUMBER,
		inUnit: '€'
	},
	{
		attribute: 'documents',
		label: 'documents',
		type: UnoFormFieldTypes.DOCUMENT_RESOURCE_MULTIPLE
	},
	{
		attribute: 'photos',
		label: 'photos',
		type: UnoFormFieldTypes.IMAGE_RESOURCE_MULTIPLE
	},
	{
		required: true,
		attribute: 'limitDate',
		label: 'limitDate',
		type: UnoFormFieldTypes.DATE
	},
	{
		required: false,
		attribute: 'workOrder',
		label: 'workOrder',
		type: UnoFormFieldTypes.TEXT
	},
	{
		required: false,
		attribute: 'criticality',
		label: 'criticality',
		sort: false,
		type: UnoFormFieldTypes.OPTIONS,
		options: Object.values(GAActionPlanCriticality).map(function(value) {
			return {value: value, label: GAActionPlanCriticalityLabel.get(value)};
		})
	},
	{
		required: false,
		attribute: 'alarmType',
		label: 'alarmType',
		sort: false,
		type: UnoFormFieldTypes.OPTIONS_MULTIPLE,
		options: Object.values(GAActionPlanAlarmType).map(function(value) {
			return {value: value, label: GAActionPlanAlarmTypeLabel.get(value)};
		})
	},
	{
		required: false,
		attribute: 'alarmOffset',
		label: 'alarmOffset',
		dateFrequencyPrecision: DateFrequencyPrecision.HOUR,
		type: UnoFormFieldTypes.DATE_FREQUENCY,
		showClear: true
	},
	{
		required: true,
		attribute: 'gapUuids',
		label: 'gaps',
		type: UnoFormFieldTypes.OPTIONS_MULTIPLE_LAZY,
		identifierAttribute: 'uuid',
		fetchOptionsLazy: async function(request) {
			const data = {
				from: request.from,
				count: request.count,
				search: request.search
			};

			try {
				const req = await Service.fetch(ServiceList.gapAnalysis.gap.list, null, null, data, Session.session);
				request.onFinish(req.response.gaps, req.response.hasMore, req.id);
			} catch {
				request.onError();
			};
		},
		fetchOptionsBatch: async function(request: InputOptionsMultipleBatchRequest): Promise<void> {
			const gaps = [];

			for (let i = 0; i < request.options.length; i++) {

				const data = {
					search: request.options[i],
					searchFields: ['ga_gap_id']
				};

				const req = await Service.fetch(ServiceList.gapAnalysis.gap.list, null, null, data, Session.session);
				gaps.push(req.response.gaps[0]);
			}

			request.onFinish(gaps);
		},
		getOptionText: function(option) {
			const identifiers: string[] = [];
			
			if (option.createdAt) {
				identifiers.push(Locale.get('createdAt') + ': ' + FormatDatePipe.formatDateTime(option.createdAt));
			}

			identifiers.push(Locale.get('origin') + ': ' + Locale.get(GAGapOriginLabel.get(option.origin)));
			
			if (option.origin === GAGapOrigin.DL50_INSPECTIONS) {
				identifiers.push(Locale.get('project') + ': ' + Locale.get('dl50'));
			} else if (option.origin === GAGapOrigin.DYNAMIC_INSPECTIONS && option.project?.name?.length > 0) {
				identifiers.push(Locale.get('project') + ': ' + option.project.name);
			}

			if (option.inspectionField) {
				if (option.inspectionField.label?.length > 0) { identifiers.push(Locale.get('fieldLabel') + ': ' + option.inspectionField.label); }
				if (option.inspectionField.text?.length > 0) { identifiers.push(Locale.get('fieldText') + ': ' + option.inspectionField.text); }
			}

			if (option.asset) {
				if (option.asset.name?.length > 0) { identifiers.push(Locale.get('asset') + ': ' + option.asset.name); }
				if (option.asset.tag?.length > 0) { identifiers.push(Locale.get('assetTag') + ': ' + option.asset.tag); }
			}			

			return identifiers.join('\n');
		}
	},
	{
		required: false,
		attribute: 'teamUuids',
		label: 'teams',
		type: UnoFormFieldTypes.TEAM_SELECTOR,
		multiple: true,
		showClear: true
	},
	{
		required: false,
		attribute: 'companyUuids',
		label: 'companies',
		type: UnoFormFieldTypes.COMPANY_SELECTOR,
		multiple: true,
		showClear: true
	}
];
