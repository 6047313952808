import {Service} from 'src/app/http/service';
import {ServiceList} from 'src/app/http/service-list';
import {RepairInspection} from 'src/app/models/repairs/inspections/repair-inspection';
import {Session} from 'src/app/session';

export type RepairInspectionListParams = {
	// Status of the Repair Inspection.
	status?: number,
	// Search value to apply on the request
	search?: string,
	// Fields to search by on the request
	searchFields?: string[],
	// Result fields to apply on the request
	resultField?: string[]
	// Where to start fetching items
	from?: number,
	// How many items to fetch
	count?: number,
	// Sort direction to apply on the request
	sortDirection?: string,
	// Field to sort by
	sortField?: string,
};

export type RepairInspectionCountParams = {
	// Status of the Repair Inspection.
	status?: number,
	// Search value to apply on the request
	search?: string,
	// Fields to search by on the request
	searchFields?: string[],
	// Result fields to apply on the request
	resultField?: string[]
};

export type RepairInspectionListReturn = {
	// If there are more Repair Inspections to fetch
	hasMore: boolean,
	// Repair Inspection List
	inspections: RepairInspection[],
	// Request id
	id: number
}

export class RepairInspectionService {

	/**
	 * List all Repair Inspections meeting the parameters.
	 *
	 * @param params - The parameters to apply on the fetch request.
	 */
	public static async list(params: RepairInspectionListParams): Promise<RepairInspectionListReturn> {
		const request = await Service.fetch(ServiceList.repairInspections.list, null, null, params, Session.session);
		return {
			hasMore: request.response.hasMore,
			inspections: request.response.inspections,
			id: request.id
		};
	}

	/**
	 * Count all Repair Inspections meeting the parameters.
	 *
	 * @param params - The parameters to apply on the fetch request.
	 */
	public static async count(params: RepairInspectionCountParams): Promise<number> {
		return (await Service.fetch(ServiceList.repairInspections.count, null, null, params, Session.session)).response.count;
	}
}
