import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {Session} from '../../../session';
import {UUID} from '../../../models/uuid';
import {APAsset} from '../../../models/asset-portfolio/asset';
import {Geolocation} from '../../../models/geolocation';

export type AssetListParams = {
	// Asset Type to filter by
	typeUuid?: UUID,
	// Search value to apply
	search?: string,
	// Fields to search by
	searchFields?: string[],
	// From where to start fetching items
	from?: number,
	// How many items to fetch
	count?: number,
	// Sort Direction to apply on the response
	sortDirection?: string,
	// Sort Field to apply on the response
	sortField?: string,
};

export type AssetCountParams = {
	// Asset Type to filter by
	typeUuid?: UUID,
	// Search value to apply
	search?: string,
	// Fields to search by
	searchFields?: string[],
};

// Asset list API request format.
export type AssetListResponse = {
	// If true, there are more items to fetch on the list.
	hasMore: boolean,
	// The fetched Assets.
	assets: APAsset[],
	// The id of the api call.
	id: number
};

export type AssetListGeolocationParams = {
	// Search value to apply
	search?: string,
	// Fields to search by
	searchFields?: string[],
};

export type AssetListGeolocationAsset = {
	// Asset UUID
	uuid: UUID,
	// Asset tag
	tag: string,
	// Asset name
	name: string,
	// Position of the asset
	position: Geolocation
};

export type AssetListGeolocation = {
	// Assets with geolocation
	assets: AssetListGeolocationAsset[]
};

export class AssetService {
	/**
	 * Get an asset from its UUID.
	 * 
	 * @param uuid - The UUID of the asset to get.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns The asset object.
	 */
	public static async get(uuid: UUID, hideLoading: boolean = false, displayError: boolean = true): Promise<APAsset> {
		const request = await Service.fetch(ServiceList.assetPortfolio.asset.get, null, null, {uuid: uuid}, Session.session, hideLoading, displayError);
		return APAsset.parse(request.response.asset);
	}

	/**
	 * Get a batch of assets from its UUIDs.
	 * 
	 * @param uuids - The UUIDs of the assets to get.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns The asset objects.
	 */
	public static async getBatch(uuids: UUID[], hideLoading: boolean = false, displayError: boolean = true): Promise<APAsset[]> {
		const request = await Service.fetch(ServiceList.assetPortfolio.asset.getBatch, null, null, {assets: uuids}, Session.session, hideLoading, displayError);
		return request.response.assets.map((a: APAsset) => { return APAsset.parse(a); });
	}

	/**
	 * List all assets matching the params.
	 * 
	 * @param params - Parameters to filter the list.
	 * @returns The list of assets.
	 */
	public static async list(params: AssetListParams): Promise<AssetListResponse> {
		const request = await Service.fetch(ServiceList.assetPortfolio.asset.list, null, null, params, Session.session);

		return {
			hasMore: request.response.hasMore,
			assets: request.response.assets.map((data: any) => {return APAsset.parse(data);}),
			id: request.id
		};
	}

	/**
	 * List distinct assets by gap UUIDs.
	 * 
	 * @param gapUuids - Gap UUIDs filter the list of assets by.
	 * @returns A list of distinct assets.
	 */
	public static async listByGapUuids(gapUuids: UUID[]): Promise<APAsset[]> {
		const request = await Service.fetch(ServiceList.assetPortfolio.asset.listByGAGaps, null, null, {gapUuids: gapUuids}, Session.session);
		return request.response.assets.map((d: any) => { return APAsset.parse(d); });
	}
	
	/**
	 * List all asset geolocation info.
	 *
	 * @param params - Parameters to filter the list.
	 * @returns The list of assets.
	 */
	public static async listGeolocation(params: AssetListGeolocationParams): Promise<AssetListGeolocation> {
		const request = await Service.fetch(ServiceList.assetPortfolio.asset.listGeolocation, null, null, params, Session.session);

		return {assets: request.response.assets};
	}

	/**
	 * Count all assets matching the params.
	 * 
	 * @param params - Parameters to filter the list.
	 */
	public static async count(params: AssetCountParams): Promise<number> {
		return (await Service.fetch(ServiceList.assetPortfolio.asset.count, null, null, params, Session.session)).response.count;
	}

	/**
	 * Get asset by tag.
	 * 
	 * @param tag - The tag of the asset to get.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns The asset object.
	 */
	public static async getByTag(tag: string, hideLoading: boolean = false, displayError: boolean = true): Promise<APAsset> {
		const request = await Service.fetch(ServiceList.assetPortfolio.asset.getTag, null, null, {tag: tag}, Session.session, hideLoading, displayError);
		return APAsset.parse(request.response.asset);
	}

	/**
	 * Get asset by QR.
	 */
	public static async getByQR(qr: string, hideLoading: boolean = false, displayError: boolean = false): Promise<APAsset> {
		const request = await Service.fetch(ServiceList.assetPortfolio.asset.getQR, null, null, {qr: qr}, Session.session, hideLoading, displayError);
		return APAsset.parse(request.response.asset);
	}

	/**
	 * Get asset by NFC.
	 */
	public static async getByNFC(nfc: string, hideLoading: boolean = false, displayError: boolean = true): Promise<APAsset> {
		const request = await Service.fetch(ServiceList.assetPortfolio.asset.getNFC, null, null, {nfc: nfc}, Session.session, hideLoading, displayError);
		return APAsset.parse(request.response.asset);
	}
}
