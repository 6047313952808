import {Session} from 'src/app/session';
import {UUID} from 'src/app/models/uuid';
import {DL50InspectionStatusType} from 'src/app/models/dl50/dl50-inspection-status';
import {Resource} from 'src/app/models/resource';
import {DL50Inspection} from 'src/app/models/dl50/dl50-inspection';
import {DL50InspectionConclusionFilterType} from 'src/app/models/dl50/dl50-inspection-conclusion-filter';
import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';

// Parameters to apply on the DL50 inspections count API request.
export type DL50InspectionsCountParams = {
	// Search value to apply
	search?: string,
	// Fields to search by
	searchFields?: string[]
	// The status to filter the inspections by
	status?: DL50InspectionStatusType
	// The final conclusion value to filter the inspections by
	finalNotesConclusion?: DL50InspectionConclusionFilterType
};

// Parameters to apply on the DL50 inspections list API request.
export type DL50InspectionsListParams = {
	// Search value to apply
	search?: string,
	// Fields to search by
	searchFields?: string[],
	// From where to start fetching items
	from?: number,
	// How many items to fetch
	count?: number,
	// Sort Direction to apply
	sortDirection?: string,
	// Sort field to sort by
	sortField?: string,
	// The status to filter the inspections by
	status?: DL50InspectionStatusType
	// The final conclusion value to filter the inspections by
	finalNotesConclusion?: DL50InspectionConclusionFilterType
};

// DL50 inspections list API request format.
export type DL50InspectionsListResponse = {
	// If true, there are more items to fetch on the list.
	hasMore: boolean,

	// The fetched inspections
	inspections: DL50InspectionListItem[],

	// ID of the request
	id: number
};

export type DL50InspectionListItem = {
	// The UUID of the inspection
	uuid: UUID,
	// The creation date of this entry on database
	createdAt: Date,
	// The last update date of this entry on database
	updatedAt: Date,
	// The label of the inspection
	label: string,
	// The description of the inspection
	description: string,
	// The status of the inspection
	status: number,
	// The final notes conclusion value of the inspection
	finalNotesConclusion: number,
	// The number of gaps associated to this inspection
	gaps: number,
	// The asset list information
	asset: {
		// The UUID of the asset
		uuid: UUID,
		// The name of the asset
		name: string,
		// The tag of the asset
		tag: string,
		// The description of the asset
		description: string,
		// The pictures of the asset
		pictures: Resource[],
	},
}

// DL50 inspections detailed list API request format.
export type DL50InspectionsDetailedListResponse = {
	// If true, there are more items to fetch on the list.
	hasMore: boolean,
	// The fetched inspections
	inspections: DL50Inspection[]
};

export class DL50InspectionService {

	/**
	 * Count inspections matching the params.
	 */
	public static async count(params: DL50InspectionsCountParams, hideLoading: boolean = true): Promise<number> {
		return (await Service.fetch(ServiceList.dl50.inspections.count, null, null, params, Session.session, hideLoading)).response.count;
	}

	/**
	 * List inspections matching the params.
	 */
	public static async list(params: DL50InspectionsListParams): Promise<DL50InspectionsListResponse> {
		const request = await Service.fetch(ServiceList.dl50.inspections.list, null, null, params, Session.session);

		return {
			hasMore: request.response.hasMore,
			inspections: request.response.inspections,
			id: request.id
		};
	}

	/**
	 * Detailed List inspections matching the params.
	 */
	public static async listDetailed(params: DL50InspectionsListParams, hideLoading: boolean = true): Promise<DL50InspectionsDetailedListResponse> {
		const request = await Service.fetch(ServiceList.dl50.inspections.listDetailed, null, null, params, Session.session, hideLoading);

		return {
			hasMore: request.response.hasMore,
			inspections: request.response.inspections
		};
	}

	/**
     * List DL50 inspections from gap UUIDs.
     */
	public static async listByGapUuids(gapUuids: UUID[]): Promise<DL50Inspection[]> {
		const request = await Service.fetch(ServiceList.dl50.inspections.listByGaps, null, null, {gapUuids: gapUuids}, Session.session);
		return request.response.inspections.map((d: any) => { return DL50Inspection.parse(d); } );
	}

	/**
	 * Get an inspection by its UUID.
	 */
	public static async get(uuid: UUID): Promise<DL50Inspection> {
		const request = await Service.fetch(ServiceList.dl50.inspections.get, null, null, {uuid: uuid}, Session.session);
		return DL50Inspection.parse(request.response.inspection);
	}

	/**
	 * Get an inspection by question response UUID.
	 */
	public static async getByResponseID(uuid: UUID): Promise<DL50Inspection> {
		const request = await Service.fetch(ServiceList.dl50.inspections.getByResponse, null, null, {uuid: uuid}, Session.session);
		return DL50Inspection.parse(request.response.inspection);
	}

	/**
	 * Delete an inspection by its UUID.
	 */
	public static async delete(uuid: UUID): Promise<void> {
		await Service.fetch(ServiceList.dl50.inspections.delete, null, null, {uuid: uuid}, Session.session);
	}

	/**
	 * Create an inspection with received data.
	 */
	public static async create(data: DL50Inspection): Promise<UUID> {
		return (await Service.fetch(ServiceList.dl50.inspections.create, null, null, data, Session.session)).response.uuid;
	}

	/**
	 * Update an inspection with received data.
	 */
	public static async update(data: DL50Inspection): Promise<void> {
		await Service.fetch(ServiceList.dl50.inspections.update, null, null, data, Session.session);
	}
}
