import {UUID} from '../../../models/uuid';
import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {Session} from '../../../session';
import {InspectionWorkflow} from '../../../models/inspections/workflow/inspection-workflow';

export type InspectionWorkflowServiceList = {
	from?: number,
	count?: number,
	search?: string,
	searchFields?: string[],
	sortField?: string,
	sortDirection?: string,
};

export type InspectionWorkflowServiceCount = {
	search?: string,
	searchFields?: string[]
};

export class InspectionWorkflowService {
	/**
	 * Get the inspection workflow by UUID.
	 * 
	 * @param uuid - The UUID of the inspection workflow to get.
	 * @returns The inspection workflow.
	 */
	public static async get(uuid: UUID): Promise<InspectionWorkflow> {
		const request = await Service.fetch(ServiceList.inspection.workflow.get, null, null, {uuid: uuid}, Session.session);
		return InspectionWorkflow.parse(request.response.workflow);
	}

	/**
	 * Load the workflow data from the project UUID.
	 *
	 * @param projectUuid - The UUID of the inspection workflow to load the workflow for.
	 * @returns The inspection workflow attached to the project.
	 */
	public static async getFromProject(projectUuid: UUID): Promise<InspectionWorkflow> {
		const request = await Service.fetch(ServiceList.inspection.workflow.getFromProject, null, null, {uuid: projectUuid}, Session.session);
		return InspectionWorkflow.parse(request.response.workflow);
	}


	/**
	 * List all inspection workflows that match the params.
	 *
	 * @param params - The parameters to apply on the fetch request.
	 */
	public static async list(params: InspectionWorkflowServiceList = {}): Promise<{hasMore: boolean, id: number, inspectionWorkflows: any[]}> {
		const request = await Service.fetch(ServiceList.inspection.workflow.list, null, null, params, Session.session);
		return {
			hasMore: request.response.hasMore,
			id: request.id,
			inspectionWorkflows: request.response.workflows.map((workflow) => {
				return InspectionWorkflow.parse(workflow);
			})
		};
	}

	/**
	 * Count all inspection workflows that match the params.
	 *
	 * @param params - The parameters to apply on the fetch request.
	 */
	public static async count(params: InspectionWorkflowServiceCount = {}): Promise<number> {
		const request = await Service.fetch(ServiceList.inspection.workflow.count, null, null, params, Session.session);
		return request.response.count;
	}
}
