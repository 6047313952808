import {generateUUID} from 'three/src/math/MathUtils';
import {DL50InspectionQuestionResponseGap} from 'src/app/models/dl50/dl50-inspection-question-response-gap';
import {UnoFormField} from '../../../components/uno-forms/uno-form/uno-form-field';
import {DL50Question} from '../../../models/dl50/masterdata/dl50-question';
import {Locale} from '../../../locale/locale';
import {UnoFormFieldTypes} from '../../../components/uno-forms/uno-form/uno-form-field-types';
import {EnumUtils} from '../../../utils/enum-utils';
import {
	DL50InspectionQuestionResult,
	DL50InspectionQuestionResultLabel
} from '../../../models/dl50/dl50-inspection-question-result';
import {DL50QuestionPossibleGap} from '../../../models/dl50/masterdata/dl50-question-possible-gap';


export class Dl50InspectionUtils {
	/**
	 * Build questions form layout from the inspection dynamic questions from master data.
	 */
	public static buildQuestionsLayout(questions: DL50Question[]): UnoFormField[] {
		const fields: UnoFormField[] = [];

		// Dynamic questions layout
		for (const q of questions) {
			const field: UnoFormField = new UnoFormField();

			field.label = q.article ? '(' + Locale.get('article') + ' ' + q.article + ') ' + q.label : q.label;
			field.attribute = q.uuid;
			field.type = UnoFormFieldTypes.COMPOSED_FIELD;
			field.required = true;

			field.fields = [
				{
					label: 'notApplicable',
					attribute: 'notApplicable',
					type: UnoFormFieldTypes.CHECKBOX
				},
				{
					label: 'evaluation',
					attribute: 'evaluation',
					type: UnoFormFieldTypes.OPTIONS,
					options: EnumUtils.values(DL50InspectionQuestionResult).map(function(value) {
						return {value: value, label: DL50InspectionQuestionResultLabel.get(value as DL50InspectionQuestionResult)};
					}),
					isActive: (object: any, row: UnoFormField): boolean => {
						return object && !object.notApplicable;
					},
					required: (object: any, row: UnoFormField): boolean => {
						return object && !object.notApplicable;
					},
					isEmpty: (object: any, row: UnoFormField): boolean => {
						return !object.notApplicable && !object.evaluation || object.evaluation === DL50InspectionQuestionResult.NONE;
					}
				},
				{
					label: 'photos',
					attribute: 'photos',
					type: UnoFormFieldTypes.IMAGE_RESOURCE_MULTIPLE,
					isActive: (object: any, row: UnoFormField): boolean => {
						return object && !object.notApplicable;
					}
				},
				{
					label: 'comments',
					attribute: 'comments',
					type: UnoFormFieldTypes.TEXT_MULTILINE,
					isActive: (object: any, row: UnoFormField): boolean => {
						return object && !object.notApplicable;
					}
				}
			];

			if (q.possibleGaps?.length > 0) {
				field.fields.push({
					label: 'gaps',
					attribute: 'gaps',
					type: UnoFormFieldTypes.REPETITIVE_FORM,
					isActive: (object: any, row: UnoFormField): boolean => {
						return object && !object.notApplicable;
					},
					createItem: (): any => {
						const responseGap: DL50InspectionQuestionResponseGap = new DL50InspectionQuestionResponseGap();
						responseGap.uuid = generateUUID();
						responseGap.questionUuid = q.uuid;

						return responseGap;
					},
					fields: [
						{
							label: 'gap',
							attribute: 'gapUuid',
							type: UnoFormFieldTypes.OPTIONS,
							required: true,
							multiple: false,
							options: q.possibleGaps?.map((g: DL50QuestionPossibleGap) => { return {label: g.label, value: g.uuid}; }) || []
						},
						{
							label: 'photos',
							attribute: 'photos',
							type: UnoFormFieldTypes.IMAGE_RESOURCE_MULTIPLE
						},
						{
							label: 'comments',
							attribute: 'comments',
							type: UnoFormFieldTypes.TEXT_MULTILINE
						}
					]
				});
			}

			fields.push(field);
		}

		return fields;
	}
}
