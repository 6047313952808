import {Component, OnInit, ViewChild} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {InspectionReportTemplate} from 'src/app/models/inspections/project/inspection-report-template';
import {UnoFormComponent} from '../../../../../components/uno-forms/uno-form/uno-form.component';
import {ScreenComponent} from '../../../../../components/screen/screen.component';
import {App} from '../../../../../app';
import {UserPermissions} from '../../../../../models/users/user-permissions';
import {Session} from '../../../../../session';
import {Service} from '../../../../../http/service';
import {ServiceList} from '../../../../../http/service-list';
import {Modal} from '../../../../../modal';
import {Locale} from '../../../../../locale/locale';
import {InspectionProject} from '../../../../../models/inspections/project/inspection-project';
import {UnoFormModule} from '../../../../../components/uno-forms/uno-form.module';
import {InspectionProjectLayout} from '../inspection-project-layout';
import {UUID} from '../../../../../models/uuid';
import {UnoButtonComponent} from '../../../../../components/uno/uno-button/uno-button.component';
import {UnoContentComponent} from '../../../../../components/uno/uno-content/uno-content.component';
import {PermissionsPipe} from '../../../../../pipes/permissions.pipe';

@Component({
	selector: 'inspection-project-edit-page',
	templateUrl: './inspection-project-edit.page.html',
	standalone: true,
	imports: [UnoContentComponent, UnoFormModule, UnoButtonComponent, TranslateModule, PermissionsPipe]
})
export class InspectionProjectEditPage extends ScreenComponent implements OnInit {
	public app: any = App;

	public layout: any = InspectionProjectLayout;

	public userPermissions: any = UserPermissions;

	public session: any = Session;

	@ViewChild('form', {static: false})
	public form: UnoFormComponent = null;

	public permissions = [UserPermissions.INSPECTION_PROJECT_EDIT, UserPermissions.INSPECTION_PROJECT_CREATE];

	/**
	 * Inspection project being edited/created on this screen, data is fetched from the API.
	 */
	public project: InspectionProject = null;

	/**
	 * Flag to indicate if the screen is being used to create a new entry.
	 */
	public createMode: boolean = false;

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		this.project = null;
		this.createMode = false;

		App.navigator.setTitle('project');

		const data = App.navigator.getData();
		if (!data || !data.uuid && !data.createMode) {
			App.navigator.pop();
			return;
		}

		if (data.uuid && data.createMode) {
			throw Error('UUID and createMode cannot be used simultaneously.');
		}

		if (data.createMode) {
			this.createMode = true;
			this.project = new InspectionProject();
			App.navigator.setTitle('create');
		} else {
			this.project = await this.loadData(data.uuid);
			if (this.project.name) {
				App.navigator.setTitle(this.project.name);
			}
		}
	}

	/**
	 * Load the inspection project data from the API from UUID.
	 */
	public async loadData(uuid: UUID): Promise<InspectionProject> {
		const request = await Service.fetch(ServiceList.inspection.project.get, null, null, {uuid: uuid}, Session.session);
		return InspectionProject.parse(request.response.project);
	}

	/**
	 * Create/update the inspection project alongside with its inspection fields.
	 *
	 * @param stayOnPage - Flag to indicate if the system should stay on the page after changes are saved.
	 */
	public async update(stayOnPage: boolean = false): Promise<void> {
		if (!this.form.requiredFilled()) {
			Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
			return;
		}

		const project = structuredClone(this.project);

		// Verify if no more than one report template is set as default
		const defaults: number = this.project.reports.filter(function(r: InspectionReportTemplate) { return r.isDefault; }).length;
		if (defaults > 1) {
			Modal.alert(Locale.get('error'), Locale.get('errorMultipleDefaultReports'));
			return;
		}

		await Service.fetch(this.createMode ? ServiceList.inspection.project.create : ServiceList.inspection.project.update, null, null, project, Session.session);
		
		if (!stayOnPage) {
			App.navigator.pop();
		}

		Modal.toast(Locale.get('updatedSuccessfully'));
	}

	/**
	 * Delete the inspection project from the database.
	 */
	public async delete(): Promise<void> {
		const confirm = await Modal.confirm(Locale.get('confirm'), Locale.get('confirmDelete'));
		if (confirm) {
			await Service.fetch(ServiceList.inspection.project.delete, null, null, {uuid: this.project.uuid}, Session.session);
			
			Modal.toast(Locale.get('deleteSuccessfully'));
			App.navigator.pop();
		}
	}

}
