@if (this.gap) {
	<div style="width:100%; height:100%;">
		<uno-tab>
			<uno-tab-section [active]="true" title="{{'gap' | translate}}">
				<!-- Gap data -->
				<uno-form [layout]="this.gapLayout" [object]="this.gap" #gapForm></uno-form>
				<!-- Inspection field -->
				<uno-form [layout]="this.fieldResponseLayout" [object]="this.fieldResponseData" [editable]="false" #gapInspectionFormField></uno-form>
				<!-- Submit buttons -->
				<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
					<uno-button (click)="this.update();">{{'save' | translate}}</uno-button>
					<uno-button (click)="this.update(true);">{{'update' | translate}}</uno-button>
					<!-- Restore gap button -->
					@if (this.gap.status === gapStatus.ARCHIVED) {
						<uno-button (click)="this.update(false, gapStatus.WAITING);">{{'restore' | translate}}</uno-button>
					}
					@else {
						<uno-button (click)="this.update(false, gapStatus.ARCHIVED);" color="warning">{{'archive' | translate}}</uno-button>
					}
					<!-- Delete -->
					@if (userPermissions.GA_GAP_DELETE | hasPermissions) {
						<uno-button (click)="this.delete();" color="error">{{'delete' | translate}}</uno-button>
					}
				</div>

				<!-- Create new Action plan-->
				@if (userPermissions.GA_ACTION_PLAN_CREATE | hasPermissions) {
					<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
						<uno-button (click)="this.createActionPlan();">{{'createActionPlan' | translate}}</uno-button>
					</div>
				}

			</uno-tab-section>

			@if ([userPermissions.ASSET_PORTFOLIO] | hasPermissions) {
				<uno-tab-section title="{{'asset' | translate}}">
					@if (this.asset) {
						<!-- Asset data -->
						<uno-form [editable]="false" [layout]="assetLayout" [object]="this.asset"></uno-form>

						<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
							<!-- Open Asset -->
							<uno-button (click)="app.navigator.navigate('/menu/asset-portfolio/asset/edit', {uuid: this.asset.uuid});">{{'asset' | translate}}</uno-button>
						</div>
					} @else {
						<uno-no-data></uno-no-data>
					}

				</uno-tab-section>
			}

			<!-- Dynamic inspection tab -->
			@if (this.gap.origin === this.gapOrigin.DYNAMIC_INSPECTIONS && ([userPermissions.INSPECTION] | hasPermissions)) {
				<uno-tab-section title="{{'inspection' | translate}}">
					@if(this.inspection) {
						<uno-form [editable]="false" [layout]="this.inspectionLayout" [object]="this.inspection"></uno-form>
						<!-- Inspection step forms -->
						@for (stepData of this.inspection.data; track stepData) {
							@if (stepData && this.steps.get(stepData.stepUuid)) {
								<uno-title>{{this.steps.get(stepData.stepUuid).name}}</uno-title>
								<uno-form [layout]="this.stepFormLayout.get(stepData.stepUuid)" [object]="stepData.data" #inspectionForm></uno-form>
							}
						}
						<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
							<!-- Open Inspection -->
							<uno-button (click)="app.navigator.navigate('/menu/inspection/edit', {uuid: this.inspection.uuid});">{{'inspection' | translate}}</uno-button>
						</div>
					} @else {
						<uno-no-data></uno-no-data>
					}
				</uno-tab-section>
			}

			<!-- DL50 inspection tab -->
			@if (this.gap.origin === this.gapOrigin.DL50_INSPECTIONS && ([userPermissions.DL50_INSPECTIONS] | hasPermissions)) {
				<uno-tab-section title="{{'dl50' | translate}}">
					@if(this.dl50Inspection) {
						<uno-form [editable]="false" [layout]="this.dl50InspectionLayouts.baseInfo" [object]="this.dl50Inspection"></uno-form>

						<uno-title>{{'safetyRequirements' | translate}}</uno-title>
						<uno-form [layout]="this.fieldResponseLayout" [object]="this.fieldResponseData" [editable]="false" #gapInspectionFormField></uno-form>

						<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
							<!-- Open DL50 Inspection -->
							<uno-button (click)="app.navigator.navigate('/menu/dl50/inspections/edit', {uuid: this.dl50Inspection.uuid});">{{'inspection' | translate}}</uno-button>
						</div>
					} @else {
						<uno-no-data></uno-no-data>
					}
				</uno-tab-section>
			}

			<uno-tab-section title="{{'recommendations' | translate}}">
				<!-- Recommendations Form -->
				<uno-form [layout]="this.recommendationsLayout" [object]="this.recommendations" #recommendationsForm></uno-form>
				<div style="display: flex; justify-content: end; margin: 10px 10px 0 0;">
					<uno-button (click)="this.update(true);">{{'update' | translate}}</uno-button>
				</div>
			</uno-tab-section>
			<uno-tab-section title="{{'history' | translate}}">
				<!-- History -->
				@if (this.history && this.history.length > 0) {
					<ion-list class="ion-no-padding ion-no-margin">
						@for (entry of this.history; track entry) {
							<ion-item class="ion-margin-horizontal ion-no-padding" lines="full">
								<ion-label>{{entry.updatedAt | formatDate}}</ion-label>
								<div class="ion-text-end">
									<ion-label>{{"status" | translate}}: {{gapStatusLabel.get(entry.status) | translate}}</ion-label>
									<ion-label>{{"userName" | translate}}: {{entry.userName}}</ion-label>
									<ion-label color="medium" style="font-size: 13px;">{{entry.userEmail}}</ion-label>
								</div>
							</ion-item>
						}
					</ion-list>
				}
				@else {
					<uno-no-data></uno-no-data>
				}
			</uno-tab-section>
		</uno-tab>
	</div>
}
