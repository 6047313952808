import {Resource} from '../resource';
import {generateUUID, UUID, UUIDIdentification} from '../uuid';
import {DL50InspectionConclusion} from './dl50-inspection-conclusion';
import {DL50InspectionQuestionResponse} from './dl50-inspection-question-response';
import {DL50InspectionStatus, DL50InspectionStatusType} from './dl50-inspection-status';
import {DL50InspectionFieldResponse} from './dl50-inspection-field-response';
import {DL50InspectionQuestionResult} from './dl50-inspection-question-result';
import {DL50Question} from './masterdata/dl50-question';

/**
 * DL50 inspection data format.
 */
export class DL50Inspection extends UUIDIdentification {
	/**
	 * The status of the inspection.
	 */
	public status: DL50InspectionStatusType = DL50InspectionStatus.IN_PROGRESS;

	/**
	 * The number of this inspection.
	 */
	public counter: number = null;

	/**
	 * Label of the inspection.
	 */
	public label: string = '';

	/**
	 * Description of the inspection.
	 */
	public description: string = '';

	/**
	 * Asset related to this inspection.
	 */
	public assetUuid: UUID = null;

	/**
	 * Team related to this inspection.
	 */
	public teamUuid: UUID = null;

	/**
	 * QR identifier of this inspection.
	 */
	public qr: string = null;

	/**
	 * The company UUID this inspection refers to.
	 */
	public companyUuid: UUID = null;
	
	/**
	 * Equipment operator.
	 */
	public equipmentOperator: string = '';

	/**
	 * Authorized operator.
	 */
	public authorizedOperator: boolean = true;
	
	/**
	 * Equipment operator.
	 */
	public equipmentOwner: string = '';
	
	/**
	 * Equipment service hours.
	 */
	public equipmentServiceHours: number = null;
	
	/**
	 * Equipment appearance.
	 */
	public equipmentAppearance: Resource[];
	
	/**
	 * Certification Location.
	 */
	public certificationLocation: string = '';
	
	/**
	 * Certification Date.
	 */
	public certificationDate: Date = null;
	
	/**
	 * If true, the inspections must have electrical tests.
	 */
	public hasElectricalTests: boolean = false;
	
	/**
	 * If true, the inspections must have load tests.
	 */
	public hasLoadTests: boolean = false;

	/**
	 * Load test load (Kg).
	 */
	public loadTestLoad: number = null;

	/**
	 * Load test range (m).
	 */
	public loadTestRange: number = null;

	/**
	 * Load test spear length.
	 */
	public loadTestSpearLength: number = null;

	/**
	 * Load test height.
	 */
	public loadTestHeight: number = null;

	/**
	 * Load test dynamic.
	 */
	public loadTestDynamic: DL50InspectionFieldResponse = new DL50InspectionFieldResponse();
	
	/**
	 * Load test dynamic overload.
	 */
	public loadTestDynamicOverload: number = null;

	/**
	 * Load test dynamic load.
	 */
	public loadTestDynamicLoad: number = null;

	/**
	 * Load test dynamic Range.
	 */
	public loadTestDynamicRange: number = null;

	/**
	 * Load test Static
	 */
	public loadTestStatic: DL50InspectionFieldResponse = new DL50InspectionFieldResponse();
	
	/**
	 * Load test StaticOverload
	 */
	public loadTestStaticOverload: number = null;

	/**
	 * Load test Static Load
	 */
	public loadTestStaticLoad: number = null;

	/**
	 * Load test Static Range
	 */
	public loadTestStaticRange: number = null;

	/**
	 * Load test Functional
	 */
	public loadTestFunctional: DL50InspectionFieldResponse = new DL50InspectionFieldResponse();
	
	/**
	 * Load test Functional Load
	 */
	public loadTestFunctionalLoad: number = null;

	/**
	 * Load test Functional Range
	 */
	public loadTestFunctionalRange: number = null;

	/**
	 * Load test MomentLimiter
	 */
	public loadTestMomentLimiter: DL50InspectionFieldResponse = new DL50InspectionFieldResponse();
	
	/**
	 * Load test MomentLimiter Load
	 */
	public loadTestMomentLimiterLoad: number = null;

	/**
	 * Load test MomentLimiter Range
	 */
	public loadTestMomentLimiterRange: number = null;

	/**
	 * Load test MaxLoadLimiter
	 */
	public loadTestMaxLoadLimiter: DL50InspectionFieldResponse = new DL50InspectionFieldResponse();
	
	/**
	 * Load test MaxLoadLimiter Load
	 */
	public loadTestMaxLoadLimiterLoad: number = null;
		
	/**
	 * Load test MaxLoadLimiter Range
	 */
	public loadTestMaxLoadLimiterRange: number = null;

	/**
	 * The working frequency of the asset in Hz.
	 */
	public frequency: number = null;
	
	/**
	 * The working nominal voltage of the asset in Volts.
	 */
	public nominalVoltage: number = null;

	/**
	 * Ground circuit continuity assessment.
	 */
	public groundCircuitContinuity: DL50InspectionFieldResponse = new DL50InspectionFieldResponse();
	
	/**
	 * Ground circuit continuity result.
	 */
	public groundCircuitContinuityResult: number = null;
	
	/**
	 * Ground circuit continuity current.
	 */
	public groundCircuitContinuityCurrent: number = null;
	
	/**
	 * Differential switches assessment.
	 */
	public differentialSwitches: DL50InspectionFieldResponse = new DL50InspectionFieldResponse();
	
	/**
	 * Differential switches open circuit time Half.
	 */
	public differentialSwitchesOpenCircuitTimeHalf: number = null;
	
	/**
	 * Differential switches open circuit time One.
	 */
	public differentialSwitchesOpenCircuitTimeOne: number = null;
	
	/**
	 * Differential switches open circuit time Two.
	 */
	public differentialSwitchesOpenCircuitTimeTwo: number = null;
	
	/**
	 * Differential switches open circuit time Five.
	 */
	public differentialSwitchesOpenCircuitTimeFive: number = null;
	
	/**
	 * Insulation resistance assessment
	 */
	public insulationResistance: DL50InspectionFieldResponse = new DL50InspectionFieldResponse();
	
	/**
	 * Insulation resistance R 
	 */
	public insulationResistanceR: number = null;
	
	/**
	 * Insulation resistance S 
	 */
	public insulationResistanceS: number = null;
	
	/**
	 * Insulation resistance T 
	 */
	public insulationResistanceT: number = null;
	
	/**
	 * Insulation resistance N 
	 */
	public insulationResistanceN: number = null;

	/**
	 * The UUID of the user that took the final notes.
	 */
	public finalNotesUserUuid: UUID = null;

	/**
	 * Final notes conclusion 
	 */
	public finalNotesConclusion: DL50InspectionConclusion = DL50InspectionConclusion.NONE;

	/**
	 * The correction deadline (in days).
	 */
	public correctionDeadline: number = 30;
	
	/**
	 * Final notes regulatory standards
	 */
	public regulatoryStandardUuids: UUID[] = [];

	/**
	 * Validation comments.
	 */
	public validationComments: string = '';
	
	/**
	 * The UUID of the user that performed the validation.
	 */
	public validationUserUuid: UUID = null;
	
	/**
	 * Validation emission location.
	 */
	public validationEmissionLocation: string = '';
	
	/**
	 * Validation emission date.
	 */
	public validationEmissionDate: Date = null;

	/**
	 * The client feedback.
	 */
	public clientFeedback: string = '';

	/**
	 * The client feedback revision version.
	 */
	public revision: number = null;

	/**
	 * Supervision rejected comments
	 */
	public supervisionRejectedComments: string = '';

	/**
	 * Inspection responses given to the questions.
	 *
	 * Questions are loaded from master data.
	 */
	public responses: DL50InspectionQuestionResponse[] = [];

	/**
	 * Initialize question responses based on question list provided.
	 *
	 * Will create and initialize new response fields.
	 *
	 * @returns List of question responses indexed by question UUID.
	 */
	public initialize(questions: DL50Question[]): {[key: string]: DL50InspectionQuestionResponse} {
		const questionResponses: {[key: string]: DL50InspectionQuestionResponse} = {};

		// Remove unused response questions if any
		for (let i = 0; i < this.responses.length; i++) {
			const question: DL50Question = questions.find((q: DL50Question): boolean => {
				return this.responses[i].questionUuid === q.uuid;
			});

			if (!question) {
				this.responses.splice(i, 1);
			}
		}

		for (let i = 0; i < questions.length; i++) {
			// Get question response from list
			let response: DL50InspectionQuestionResponse = this.responses.find(function(r: DL50InspectionQuestionResponse): boolean {
				return r.questionUuid === questions[i].uuid;
			});

			// If response was not found create new one
			if (!response) {
				response = new DL50InspectionQuestionResponse();
				response.notApplicable = true;
				response.uuid = generateUUID();
				response.inspectionUuid = this.uuid;
				response.questionUuid = questions[i].uuid;
				this.responses.push(response);
			}

			questionResponses[questions[i].uuid] = response;
		}

		return questionResponses;
	}

	/**
	 * Clean up unused data.
	 */
	public clean(): void {
		// Clean electrical tests data tests when it doesn't have electrical tests
		if (!this.hasElectricalTests) {
			this.frequency = null;
			this.nominalVoltage = null;
			this.groundCircuitContinuity = null;
			this.groundCircuitContinuityResult = null;
			this.groundCircuitContinuityCurrent = null;
			this.differentialSwitches = null;
			this.differentialSwitchesOpenCircuitTimeHalf = null;
			this.differentialSwitchesOpenCircuitTimeOne = null;
			this.differentialSwitchesOpenCircuitTimeTwo = null;
			this.differentialSwitchesOpenCircuitTimeFive = null;
			this.insulationResistance = null;
			this.insulationResistanceR = null;
			this.insulationResistanceS = null;
			this.insulationResistanceT = null;
			this.insulationResistanceN = null;
		} else {
			if (!this.groundCircuitContinuity || this.groundCircuitContinuity.notApplicable) {
				this.groundCircuitContinuityResult = null;
				this.groundCircuitContinuityCurrent = null;
			}

			if (!this.differentialSwitches || this.differentialSwitches.notApplicable) {
				this.differentialSwitchesOpenCircuitTimeHalf = null;
				this.differentialSwitchesOpenCircuitTimeOne = null;
				this.differentialSwitchesOpenCircuitTimeTwo = null;
				this.differentialSwitchesOpenCircuitTimeFive = null;
			}

			if (!this.insulationResistance || this.insulationResistance.notApplicable) {
				this.insulationResistanceR = null;
				this.insulationResistanceS = null;
				this.insulationResistanceT = null;
				this.insulationResistanceN = null;
			}
		}

		// Clean load tests data tests when it doesn't have load tests
		if (!this.hasLoadTests) {
			this.loadTestLoad = null;
			this.loadTestRange = null;
			this.loadTestSpearLength = null;
			this.loadTestHeight = null;
			this.loadTestDynamic = null;
			this.loadTestDynamicOverload = null;
			this.loadTestDynamicLoad = null;
			this.loadTestDynamicRange = null;
			this.loadTestStatic = null;
			this.loadTestStaticOverload = null;
			this.loadTestStaticLoad = null;
			this.loadTestStaticRange = null;
			this.loadTestFunctional = null;
			this.loadTestFunctionalLoad = null;
			this.loadTestFunctionalRange = null;
			this.loadTestMomentLimiter = null;
			this.loadTestMomentLimiterLoad = null;
			this.loadTestMomentLimiterRange = null;
			this.loadTestMaxLoadLimiter = null;
			this.loadTestMaxLoadLimiterLoad = null;
			this.loadTestMaxLoadLimiterRange = null;
		} else {
			if (!this.loadTestDynamic || this.loadTestDynamic.notApplicable) {
				this.loadTestDynamicOverload = null;
				this.loadTestDynamicLoad = null;
				this.loadTestDynamicRange = null;
			}

			if (!this.loadTestStatic || this.loadTestStatic.notApplicable) {
				this.loadTestStaticOverload = null;
				this.loadTestStaticLoad = null;
				this.loadTestStaticRange = null;
			}

			if (!this.loadTestFunctional || this.loadTestFunctional.notApplicable) {
				this.loadTestFunctionalLoad = null;
				this.loadTestFunctionalRange = null;
			}

			if (!this.loadTestMomentLimiter || this.loadTestMomentLimiter.notApplicable) {
				this.loadTestMomentLimiterLoad = null;
				this.loadTestMomentLimiterRange = null;
			}

			if (!this.loadTestMaxLoadLimiter || this.loadTestMaxLoadLimiter.notApplicable) {
				this.loadTestMaxLoadLimiterLoad = null;
				this.loadTestMaxLoadLimiterRange = null;
			}
		}

		// Clean responses
		for (const response of this.responses) {
			if (response.notApplicable) {
				response.evaluation = DL50InspectionQuestionResult.NONE;
				response.gaps = [];
				response.comments = '';
				response.photos = [];
			}
		}

		// Clean correction deadline when the conclusion doesn't require it.
		if (this.finalNotesConclusion !== DL50InspectionConclusion.GAPS_LIGHT && this.finalNotesConclusion !== DL50InspectionConclusion.GAPS_SEVERE) {
			this.correctionDeadline = 0;
		}
	}

	/**
	 * Validates if the final notes conclusion fits all the evaluations given on the inspection.
	 * 
	 * If the conclusion is OK, every (applicable) evaluation should be OK too.
	 * 
	 * If the conclusion has non-conformities (light or heavy) or inspection is NOK, there should exist at least on NOK evaluation. 
	 * 
	 * @param inspection - The inspection to validate the evaluations agains the conclusion.
	 * @returns true if the inspection has inconsistent evaluations against conclusion option, false otherwise.
	 */
	public static inconsistentEvaluations(inspection: DL50Inspection): boolean {		
		return inspection.finalNotesConclusion === DL50InspectionConclusion.OK && inspection.responses.filter((r: DL50InspectionQuestionResponse) => { return !r.notApplicable && r.evaluation === DL50InspectionQuestionResult.NOK; }).length > 0 || 
				(inspection.finalNotesConclusion === DL50InspectionConclusion.NOK || inspection.finalNotesConclusion === DL50InspectionConclusion.GAPS_LIGHT || inspection.finalNotesConclusion === DL50InspectionConclusion.GAPS_SEVERE) && 
				inspection.responses.filter((r: DL50InspectionQuestionResponse) => { return r.notApplicable || r.evaluation === DL50InspectionQuestionResult.OK; }).length === inspection.responses.length;
	}

	/**
	 * Parse data into Inspection object.
	 *
	 * @param data - Data to be parsed.
	 * @returns Object of the correct type.
	 */
	public static parse(data: any): DL50Inspection {
		const inspection = new DL50Inspection();

		inspection.uuid = data.uuid;
		inspection.createdAt = new Date(data.createdAt);
		inspection.updatedAt = new Date(data.updatedAt);

		// Inspection base info
		inspection.status = data.status;
		inspection.counter = data.counter;
		inspection.label = data.label;
		inspection.description = data.description;
		inspection.assetUuid = data.assetUuid;
		inspection.teamUuid = data.teamUuid;
		inspection.qr = data.qr;

		// General data
		inspection.companyUuid = data.companyUuid;
		inspection.authorizedOperator = data.authorizedOperator;
		inspection.equipmentOperator = !inspection.authorizedOperator ? data.equipmentOperator : '';
		inspection.equipmentOwner = data.equipmentOwner;
		inspection.equipmentServiceHours = data.equipmentServiceHours;
		inspection.equipmentAppearance = Resource.parseArray(data.equipmentAppearance);
		inspection.certificationLocation = data.certificationLocation;
		inspection.certificationDate = data.certificationDate ? new Date(data.certificationDate) : null;
		inspection.hasElectricalTests = data.hasElectricalTests === true;
		inspection.hasLoadTests = data.hasLoadTests === true;

		// Load test
		inspection.loadTestLoad = data.loadTestLoad; 
		inspection.loadTestRange = data.loadTestRange; 
		inspection.loadTestSpearLength = data.loadTestSpearLength; 
		inspection.loadTestHeight = data.loadTestHeight; 
		inspection.loadTestDynamic = DL50InspectionFieldResponse.parse(data.loadTestDynamic);
		inspection.loadTestDynamicOverload = data.loadTestDynamicOverload; 
		inspection.loadTestDynamicLoad = data.loadTestDynamicLoad; 
		inspection.loadTestDynamicRange = data.loadTestDynamicRange; 
		inspection.loadTestStatic = DL50InspectionFieldResponse.parse(data.loadTestStatic);
		inspection.loadTestStaticOverload = data.loadTestStaticOverload; 
		inspection.loadTestStaticLoad = data.loadTestStaticLoad; 
		inspection.loadTestStaticRange = data.loadTestStaticRange; 
		inspection.loadTestFunctional = DL50InspectionFieldResponse.parse(data.loadTestFunctional);
		inspection.loadTestFunctionalLoad = data.loadTestFunctionalLoad; 
		inspection.loadTestFunctionalRange = data.loadTestFunctionalRange; 
		inspection.loadTestMomentLimiter = DL50InspectionFieldResponse.parse(data.loadTestMomentLimiter);
		inspection.loadTestMomentLimiterLoad = data.loadTestMomentLimiterLoad; 
		inspection.loadTestMomentLimiterRange = data.loadTestMomentLimiterRange; 
		inspection.loadTestMaxLoadLimiter = DL50InspectionFieldResponse.parse(data.loadTestMaxLoadLimiter);
		inspection.loadTestMaxLoadLimiterLoad = data.loadTestMaxLoadLimiterLoad; 
		inspection.loadTestMaxLoadLimiterRange = data.loadTestMaxLoadLimiterRange; 

		// Electrical test
		inspection.frequency = data.frequency;
		inspection.nominalVoltage = data.nominalVoltage;
		inspection.groundCircuitContinuity = DL50InspectionFieldResponse.parse(data.groundCircuitContinuity);
		inspection.groundCircuitContinuityResult = data.groundCircuitContinuityResult;
		inspection.groundCircuitContinuityCurrent = data.groundCircuitContinuityCurrent;
		inspection.differentialSwitches = DL50InspectionFieldResponse.parse(data.differentialSwitches);
		inspection.differentialSwitchesOpenCircuitTimeHalf = data.differentialSwitchesOpenCircuitTimeHalf;
		inspection.differentialSwitchesOpenCircuitTimeOne = data.differentialSwitchesOpenCircuitTimeOne;
		inspection.differentialSwitchesOpenCircuitTimeTwo = data.differentialSwitchesOpenCircuitTimeTwo;
		inspection.differentialSwitchesOpenCircuitTimeFive = data.differentialSwitchesOpenCircuitTimeFive;
		inspection.insulationResistance = DL50InspectionFieldResponse.parse(data.insulationResistance);
		inspection.insulationResistanceR = data.insulationResistanceR;
		inspection.insulationResistanceS = data.insulationResistanceS;
		inspection.insulationResistanceT = data.insulationResistanceT;
		inspection.insulationResistanceN = data.insulationResistanceN;

		// Final notes
		inspection.finalNotesUserUuid = data.finalNotesUserUuid;
		inspection.finalNotesConclusion = data.finalNotesConclusion;
		inspection.correctionDeadline = data.correctionDeadline;
		inspection.regulatoryStandardUuids = data.regulatoryStandardUuids || [];

		// Validation
		inspection.validationComments = data.validationComments;
		inspection.validationUserUuid = data.validationUserUuid;
		inspection.validationEmissionLocation = data.validationEmissionLocation;
		inspection.validationEmissionDate = data.validationEmissionDate ? new Date(data.validationEmissionDate) : null;
		inspection.revision = data.revision;
		
		// Client Feedback
		inspection.clientFeedback = data.clientFeedback;

		// Supervision rejected
		inspection.supervisionRejectedComments = data.supervisionRejectedComments;

		// Responses
		inspection.responses = data.responses ? data.responses.map(function(r) {
			return DL50InspectionQuestionResponse.parse(r);
		}) : [];

		return inspection;
	}
}
