import {DateFrequency} from '../../date-frequency';
import {Resource} from '../../resource';
import {UUID, UUIDIdentification} from '../../uuid';
import {GAActionPlanCriticality} from './action-plan-criticality';
import {GAActionPlanAlarmType} from './action-plan-alarm-type';
import {GAActionPlanStatus} from './action-plan-status';

export class GAActionPlan extends UUIDIdentification {
	/**
	 * Description of the action plan.
	 */
	public description: string = '';

	/**
	 * Status of the action plan.
	 */
	public status: typeof GAActionPlanStatus[keyof typeof GAActionPlanStatus] = GAActionPlanStatus.PROPOSAL;

	/**
	 * Esimated cost in to solve the gap or gaps associated with this action plan.
	 */
	public cost: number = 0;
	
	/**
	 * Documents related to the action plan. Ex: Budgets, data sheets etc...
	 */
	public documents: Resource[] = [];

	/**
	 * Photos related to the action plan. Usually photos of the asset problem.
	 */
	public photos: Resource[] = [];

	/**
	 * The limit date when the gap should be solved.
	 */
	public limitDate: Date = null;

	/**
	 * The work order of the gap. Usually it is the identifier/order of the budget.
	 */
	public workOrder: string = '';

	/**
	 * Criticality of the action plan.
	 */
	public criticality: typeof GAActionPlanCriticality[keyof typeof GAActionPlanCriticality] = GAActionPlanCriticality.MEDIUM;

	/**
	 * Type of alarm to be sent to the user.
	 */
	public alarmType: (typeof GAActionPlanAlarmType)[keyof typeof GAActionPlanAlarmType][] = [];	

	/**
	 * Alarm offset defines the time before the alarm should be sent to user.
	 */
	public alarmOffset: DateFrequency = new DateFrequency();

	/**
	 * The UUIDs of the gaps included on this action plan.
	 */
	public gapUuids: UUID[] = [];

	/**
	 * The UUIDs of the teams assigned to this action plan.
	 */
	public teamUuids: UUID[] = [];
	
	/**
	 * The UUIDs of the companies assigned to this action plan.
	 */
	public companyUuids: UUID[] = [];

	/**
	 * Parse JSON data received from the API into an object of the correct type.
	 * 
	 * @param data - Object received from API.
	 * @returns GAActionPlan object instance built from the data received.
	 */
	public static parse(data: any): GAActionPlan {
		const actionPlan = new GAActionPlan();

		actionPlan.uuid = data.uuid;
		actionPlan.updatedAt = new Date(data.updatedAt);
		actionPlan.createdAt = new Date(data.createdAt);

		actionPlan.description = data.description;
		actionPlan.status = data.status;
		actionPlan.cost = data.cost;
		actionPlan.documents = data.documents;
		actionPlan.photos = data.photos;
		actionPlan.limitDate = data.limitDate ? new Date(data.limitDate) : null;
		actionPlan.workOrder = data.workOrder;
		actionPlan.criticality = data.criticality;
		actionPlan.alarmType = data.alarmType;
		
		if (data.alarmOffset) {
			actionPlan.alarmOffset = DateFrequency.parse(data.alarmOffset);
		}
		
		actionPlan.gapUuids = data.gapUuids;
		actionPlan.teamUuids = data.teamUuids;
		actionPlan.companyUuids = data.companyUuids;

		return actionPlan;
	}
}
