import {Component, OnInit} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';
import {MenuCardsGroup, MenuCardsGroupsComponent} from 'src/app/components/layout/menu-cards-groups/menu-cards-groups.component';
import {DL50InspectionStatus, DL50InspectionStatusLabel} from 'src/app/models/dl50/dl50-inspection-status';
import {App} from '../../../../app';
import {ScreenComponent} from '../../../../components/screen/screen.component';
import {UserPermissions} from '../../../../models/users/user-permissions';
import {UnoContentComponent} from '../../../../components/uno/uno-content/uno-content.component';

import {DL50InspectionService} from '../../services/dl50-inspection.service';

@Component({
	selector: 'dl50-menu-page',
	templateUrl: 'dl50-menu.page.html',
	standalone: true,
	imports: [UnoContentComponent, MenuCardsGroupsComponent, IonicModule, TranslateModule]
})
export class DL50MenuPage extends ScreenComponent implements OnInit {
	public permissions = [UserPermissions.DL50];

	public ngOnInit(): void {
		super.ngOnInit();

		App.navigator.setTitle('DL50');
	}

	/**
	 * List of base cards options in this menu.
	 */
	public menuCards: MenuCardsGroup[] = [
		{
			label: 'global',
			permissions: [UserPermissions.DL50_SUPERVISOR, UserPermissions.DL50_INSPECTIONS_CREATE, UserPermissions.DL50_MASTER_DATA_QUESTIONS_CREATE, UserPermissions.DL50_MASTER_DATA_QUESTIONS_EDIT, UserPermissions.DL50_MASTER_DATA_REGULATORY_STANDARDS_CREATE, UserPermissions.DL50_MASTER_DATA_REGULATORY_STANDARDS_EDIT],
			options: [
				{
					label: 'create',
					route: '/menu/dl50/inspections/edit',
					data: {createMode: true},
					icon: 'add',
					badge: null,
					permissions: [UserPermissions.DL50_SUPERVISOR, UserPermissions.DL50_INSPECTIONS_CREATE]
				},
				{
					label: DL50InspectionStatusLabel.get(DL50InspectionStatus.ALL),
					route: '/menu/dl50/inspections/list',
					data: {status: DL50InspectionStatus.ALL},
					icon: 'list',
					badge: (async() => {
						return await DL50InspectionService.count({status: DL50InspectionStatus.ALL});
					})(),
					permissions: [UserPermissions.DL50_SUPERVISOR]
				},
				{
					label: 'masterdata',
					route: '/menu/dl50/master-data/menu',
					icon: './assets/components/menu/master.svg',
					permissions: [UserPermissions.DL50_SUPERVISOR, UserPermissions.DL50_MASTER_DATA_QUESTIONS_CREATE, UserPermissions.DL50_MASTER_DATA_QUESTIONS_EDIT, UserPermissions.DL50_MASTER_DATA_REGULATORY_STANDARDS_CREATE, UserPermissions.DL50_MASTER_DATA_REGULATORY_STANDARDS_EDIT],
					data: null,
					badge: null
				}
			]
		},
		{
			label: 'inProgress',
			permissions: [UserPermissions.DL50_SUPERVISOR, UserPermissions.DL50_INSPECTOR],
			options: [
				{
					label: DL50InspectionStatusLabel.get(DL50InspectionStatus.IN_PROGRESS),
					route: '/menu/dl50/inspections/list',
					data: {status: DL50InspectionStatus.IN_PROGRESS},
					icon: './assets/icons/material/edit_note/outline.svg',
					badge: (async() => {
						return await DL50InspectionService.count({status: DL50InspectionStatus.IN_PROGRESS});
					})(),
					permissions: [UserPermissions.DL50_SUPERVISOR, UserPermissions.DL50_INSPECTOR]
				},
				{
					label: DL50InspectionStatusLabel.get(DL50InspectionStatus.SUPERVISION_REJECTED),
					route: '/menu/dl50/inspections/list',
					data: {status: DL50InspectionStatus.SUPERVISION_REJECTED},
					icon: 'thumbs-down',
					badge: (async() => {
						return await DL50InspectionService.count({status: DL50InspectionStatus.SUPERVISION_REJECTED});
					})(),
					permissions: [UserPermissions.DL50_SUPERVISOR, UserPermissions.DL50_INSPECTOR]
				},
				{
					label: DL50InspectionStatusLabel.get(DL50InspectionStatus.CLIENT_FEEDBACK),
					route: '/menu/dl50/inspections/list',
					data: {status: DL50InspectionStatus.CLIENT_FEEDBACK},
					icon: './assets/icons/material/rate_review/outline.svg',
					badge: (async() => {
						return await DL50InspectionService.count({status: DL50InspectionStatus.CLIENT_FEEDBACK});
					})(),
					permissions: [UserPermissions.DL50_SUPERVISOR, UserPermissions.DL50_INSPECTOR]
				}
			]
		},
		{
			label: 'underSupervision',
			permissions: [UserPermissions.DL50_SUPERVISOR],
			options: [
				{
					label: DL50InspectionStatusLabel.get(DL50InspectionStatus.VALIDATION),
					route: '/menu/dl50/inspections/list',
					data: {status: DL50InspectionStatus.VALIDATION},
					icon: './assets/icons/material/rate_review/outline.svg',
					badge: (async() => {
						return await DL50InspectionService.count({status: DL50InspectionStatus.VALIDATION});
					})(),
					permissions: [UserPermissions.DL50_SUPERVISOR]
				}
			]
		},
		{
			label: 'finished',
			permissions: [UserPermissions.DL50_SUPERVISOR, UserPermissions.DL50_INSPECTOR, UserPermissions.DL50_CLIENT],
			options: [
				{
					label: DL50InspectionStatusLabel.get(DL50InspectionStatus.FINISHED),
					route: '/menu/dl50/inspections/list',
					data: {status: DL50InspectionStatus.FINISHED},
					icon: 'checkbox-outline',
					badge: (async() => {
						return await DL50InspectionService.count({status: DL50InspectionStatus.FINISHED});
					})(),
					permissions: [UserPermissions.DL50_SUPERVISOR, UserPermissions.DL50_INSPECTOR, UserPermissions.DL50_CLIENT]
				}
			]
		}
	];
}
