import {Injectable} from '@angular/core';
import {Observation} from '../../../models/observations/observation';
import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {Session} from '../../../session';
import {UUID} from '../../../models/uuid';

export type ObservationServiceList = {
	// Status to apply on the fetch request
	status?: number,
	// Search value to apply
	search?: string,
	// Fields to search by
	searchFields?: string[],
	// From where to start fetching items
	from?: number,
	// How many items to fetch
	count?: number,
	// Sort Direction to apply on the response
	sortDirection?: string,
	// Sort Field to apply on the response
	sortField?: string
};

export type ObservationServiceListResponse = {
	// If there are more observations to fetch
	hasMore: boolean,
	// The request id
	id: number,
	// The observations list
	observations: Observation[]
};

export type ObservationServiceCount = {
	// Status to apply on the fetch request
	status?: number,
	// Search value to apply
	search?: string,
	// Fields to search by
	searchFields?: string[]
};

@Injectable({providedIn: 'root'})
export class ObservationService {
	/**
	 * Get observation from API from its UUID.
	 */
	public async get(uuid: UUID): Promise<Observation> {
		const request = await Service.fetch(ServiceList.observation.get, null, null, {uuid: uuid}, Session.session);
		return Observation.parse(request.response.observation);
	}

	/**
	 * Delete observation from API from its UUID.
	 */
	public async delete(uuid: UUID): Promise<void> {
		await Service.fetch(ServiceList.observation.delete, null, null, {uuid: uuid}, Session.session);
	}

	/**
	 * Update observation in API
	 */
	public async update(observation: Observation): Promise<void> {
		await Service.fetch(ServiceList.observation.update, null, null, observation, Session.session);
	}

	/**
	 * List all observations that match the params.
	 *
	 * @param params - The parameters to apply on the fetch request.
	 */
	public static async list(params: ObservationServiceList = {}): Promise<ObservationServiceListResponse> {
		const request = await Service.fetch(ServiceList.observation.list, null, null, params, Session.session);

		return {
			hasMore: request.response.hasMore,
			id: request.id,
			observations: request.response.observations.map(function(observation: any) {return Observation.parse(observation);})
		};
	}

	/**
	 * Count all observations that match the params.
	 *
	 * @param params - The parameters to apply on the fetch request.
	 */
	public static async count(params: ObservationServiceCount = {}): Promise<number> {
		return (await Service.fetch(ServiceList.observation.count, null, null, params, Session.session)).response.count;
	}
}
